<template>
  <div class="input-disabled" :class="isNotSetData && 'input-notset-data'">
    <div
      :ref="refElement"
      class="input-disable"
      :class="isSmallInput && 'small-input'"
      @mouseover="handleTooltip($event)"
      @mouseleave="handleTooltip($event, 'mouseleave')"
      @mousemove="updateTooltipPosition($event)"
    >
      {{ textFormat }}
    </div>
    <div v-if="isNotSetData" class="label_notsetdata">{{ $t('cfp_emissions_product.error_not_set') }}</div>
  </div>
</template>

<script>
import { getWidthText } from '@/utils/calcTextWidth';
import { formatNumberByConditions } from '@/utils/convertNumber';
export default {
  name: 'InputDisable',
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    isSmallInput: {
      type: Boolean,
      default: false,
    },
    isNotSetData: {
      type: Boolean,
      default: false,
    },
    isNumberType: {
      type: Boolean,
      default: false,
    },
    idNode: {
      type: String,
      default: '',
    },
  },
  computed: {
    textFormat() {
      if (!this.isNumberType) return this.text;
      return formatNumberByConditions(this.text, {
        isRealNumber: true,
        isAlowMore25digit: false,
      });
    },
    tooltipStyle() {
      return 'position: absolute;width: max-content;background: #ffffff;display: block;justify-content: left;border-radius: 4px;color: #404d50;margin: 0 auto;font-size: 15px;font-weight: 500;padding: 10px;height: unset;max-width: 100%;word-break: break-all;left: 0;top: 40px;z-index: 9999;box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);';
    },
    refElement() {
      return this.idNode !== '' ? this.idNode : `inputDisable-${this.randomTooltipId}`;
    },
    randomTooltipId() {
      return Math.random().toString(36);
    },
  },
  methods: {
    handleTooltip(event, type) {
      if (!this.isShowTooltip()) return;
      if (type === 'mouseleave') {
        event.target.style.cursor = 'default';
        const tooltipEle = document.getElementById(this.randomTooltipId);
        if (tooltipEle) tooltipEle.remove();
      } else {
        event.target.style.cursor = 'pointer';
        const newDiv = document.createElement('div');
        newDiv.textContent = this.textFormat;
        newDiv.style = this.tooltipStyle;
        newDiv.id = this.randomTooltipId;
        // Thêm phần tử mới vào body của trang
        document.body.appendChild(newDiv);
      }
    },
    updateTooltipPosition(event) {
      const tooltipEle = document.getElementById(this.randomTooltipId);
      if (!tooltipEle) return;
      const top = event.clientY + window.scrollY - 50;
      const left = event.clientX + window.scrollX;
      tooltipEle.style.maxWidth = 400 + 'px';
      tooltipEle.style.top = top + 'px'; // Lấy vị trí dọc chuột
      tooltipEle.style.left = left + 'px'; // Lấy vị trí ngang chuột
      if (event.clientX + window.scrollX + 450 >= window.innerWidth) {
        tooltipEle.style.left = left - (event.clientX + window.scrollX + 450 - window.innerWidth) + 'px';
      }
      if (tooltipEle.clientHeight > 41) {
        tooltipEle.style.top = top - tooltipEle.clientHeight / 2 + 'px';
      }
    },
    isShowTooltip() {
      let divWidth = this.$refs[this.refElement]?.clientWidth - 32; // 32 = padding left + right
      if (this.idNode !== '') {
        divWidth = this.$refs[this.idNode]?.clientWidth - 20; // 20 = padding left + right
      }
      const textWidth = getWidthText(this.textFormat, { fontSize: '14px', fontWeight: 500, letterSpacing: '0.42px' });
      return divWidth < textWidth ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-disable {
  border-radius: 4px;
  border: 1px solid var(--Devider-Black, rgba(42, 42, 48, 0.1));
  height: 40px;
  padding: 7px 16px 9px 16px;
  color: $monoBlack;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.small-input {
    font-size: 11px;
    line-height: 16px;
    min-height: 28px;
    font-weight: 700;
    padding: 4px 10px 6px 10px;
    height: 28px;
  }
}

.input-notset-data {
  .label_notsetdata {
    color: $redMid;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    margin-top: 4px;
    text-align: left;
    padding: 0px 11px;
  }
  .input-disable {
    border: 2px solid $redMid;
  }
}

.input-disabled {
  position: relative;
  .tooltip {
    position: absolute;
    width: max-content;
    background: $monoWhite;
    display: block;
    justify-content: left;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    height: unset;
    max-width: 100%;
    word-break: break-all;
    left: 0;
    top: 40px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}
</style>
