<template>
  <dialog-not-include-close-icon :dialog="dialog" @close="close" :isStyleForCardText="false" :isHiddenScroll="false">
    <template v-slot:title>
      <span ref="title" class="title-popup">{{ $t('new_cfp.title_popup_export_process_boundary') }}</span>
    </template>
    <template v-slot:content>
      <div class="select mb-4 mt-4">
        <infinite-scroll
          item-text="name"
          item-value="id"
          :items="boundariesList"
          :model="valueData"
          inputFieldElementId="selectAddProcess_inputField"
          dropdownElementId="selectAddProcess_dropdown"
          searchInput="selectAddProcess_searchInput"
          tooltipColumn="tooltipAddProcess_tooltipColumn"
          isReturnObject
          @updateMasterDb="updatePulldownData"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <common-button @action="close" :label="$t('new_cfp.button_cancel')" />
      <common-button
        :label="$t('new_cfp.button_popup_export_process_boundary')"
        @action="submit"
        class="right-button-popup"
      />
    </template>
  </dialog-not-include-close-icon>
</template>
<script>
import DialogNotIncludeCloseIcon from '@/components/dialogs/base/DialogNotIncludeCloseIcon';
import CommonButton from '@/components/commonApp/Button.vue';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
export default {
  name: 'ExportProcessBoundary',
  components: { DialogNotIncludeCloseIcon, CommonButton, InfiniteScroll },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    boundariesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueData: {
        id: '',
        text: '',
      },
    };
  },
  mounted() {
    this.valueData = this.boundariesList[0];
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit', this.valueData);
    },
    updatePulldownData(newVal) {
      if (newVal) {
        this.valueData = newVal;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';

.v-application .select {
  padding: 0px 32px;
}


::v-deep .sub-title {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}

::v-deep .select-item .v-input__slot {
  background: $monoWhite !important;
}
</style>
