<template>
  <div class="product-informations">
    <!-- product name infor -->
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('b_register_product.label_product_name') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <input-field
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.name"
          :isClearField="true"
          @update:inputValue="handleUpdateProduct($event, 'name')"
          @blur="autofillCodeField"
          @clearDataField="clearDataField"
          class="product-informations-field-input"
        />
        <input-disable v-else :text="productInfoState.name" />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('b_register_product.label_product_code') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <input-field
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.code"
          :isReadOnly="!productInfo.name || isMappingNameCode"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'code')"
        />
        <input-disable v-else :text="productInfoState.code" />
      </div>
    </div>

    <!-- product unit infor -->
    <div class="product-informations-form unit">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_unit') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
          <tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_product_unit')" />
        </div>
        <input-field
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.unit"
          :errorMessages="productErrorMessages.productUnitErrorMessages"
          :isReadOnly="isReadOnly && isDetailProduct"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'unit')"
          @blur="handleCheckProductUnit"
          @focus="resetErrorMessage('productUnitErrorMessages')"
        />
        <input-disable v-else :text="productInfoState.unit" />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_declared_unit') }}</div>
          <tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_declared_unit')" />
        </div>
        <infinite-scroll
          dropdownElementId="dropdownElementUnitId"
          inputFieldElementId="inputFieldElementUnitId"
          searchInput="searchInputUnitId"
          :items="declaredUnitList"
          :model="productInfoState.declareUnit"
          item-text="value"
          item-value="key"
          :isDisabled="(!canEditProductInfo || isReadOnly) && isDetailProduct"
          typeList="unit"
          :isReadOnly="isReadOnly && isDetailProduct"
          :isCfp="true"
          @updateMasterDb="updateDeclareUnit"
          @focus="resetErrorMessage('productDeclareUnitErrorMessage')"
          @blur="handleCheckProductDeclareUnit(productInfoState.declareUnit)"
          :label="getLabelDetail(productInfoState.declareUnit) || $t('new_cfp.label_select_unit')"
        />
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_cost') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
          <tooltip :tooltipText="$t('cfp_emissions_product.tooltip_description_product_cost')" />
        </div>
        <input-field
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.product_quantity"
          :isNumberType="true"
          :isReadOnly="isReadOnly && isDetailProduct"
          class="product-informations-field-input"
          @update:inputValue="handleUpdateProduct($event, 'product_quantity')"
          @blur="handleCheckProductQuantity"
          @focus="resetErrorMessage('productQuantityErrorMessages')"
        />
        <input-disable
          v-else
          :text="formatNumber(productInfoState.product_quantity) || getLabelDetail(productInfoState.product_quantity)"
        />
      </div>
    </div>

    <!-- product lot info and use cfp in other products -->
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('new_cfp.label_use_cfp_in_other_products') }}</div>
          <tooltip :tooltipText="$t('new_cfp.tooltip_description_use_cfp_in_other_products')" />
        </div>
        <div class="select-use-cfp">
          <v-select
            :menu-props="{ contentClass: 'select-menu export-select z-index-high' }"
            solo
            fat
            class="select-default list-menu--select select-item"
            :items="useCFPInOtherProducts"
            v-model="productInfoState.is_reuse"
            :disabled="isDisabledUseCFPInOtherProducts"
          ></v-select>
        </div>
      </div>
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_lot_number') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <input-field
          v-if="!(isReadOnly && isDetailProduct)"
          :inputValue="productInfoState.lotNumber"
          @update:inputValue="handleUpdateProduct($event, 'lotNumber')"
          class="product-informations-field-input"
        />
        <input-disable v-else :text="productInfoState.lotNumber" />
      </div>
    </div>
    <!-- registration new date -->
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('new_cfp.label_registration_date') }}</div>
          <div class="required-text">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <div class="date-select-group">
          <v-select
            :placeholder="labelSelectYear"
            :menu-props="{ contentClass: 'select-menu export-select z-index-high' }"
            solo
            fat
            :items="productDate.yearsList"
            v-model="productInfoState.year"
            :disabled="isDisabledDate"
            class="select-default list-menu--select select-item"
          ></v-select>
          <v-select
            :disabled="isDisabledDate"
            :placeholder="labelSelectMonth"
            :menu-props="{ contentClass: 'select-menu export-select z-index-high' }"
            solo
            fat
            :items="productDate.monthsList"
            v-model="productInfoState.month"
            isDisabledDate
            class="select-default list-menu--select select-item"
          ></v-select>
        </div>
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_category') }}</div>
        </div>
        <infinite-scroll-include-search
          dropdownElementId="dropdownElementCategorySearch"
          inputFieldElementId="inputFieldElementCategorySearch"
          searchInput="searchInputCategorySearch"
          tooltipColumn="tooltipsearch"
          :items="categoryListProduct"
          :model="productInfoState.category"
          item-text="name"
          item-value="name"
          :isDisabled="isReadOnly && isDetailProduct"
          :isReturnObject="false"
          @updateMasterDb="updateProductCategory"
        />
      </div>
    </div>

    <!-- Category price Currency  -->
    <div class="product-informations-form unit">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_product_unit_price') }}</div>
        </div>

        <div class="date-select-group">
          <div class="unit-price text-left product-price">
            <input-field
              v-if="!(isReadOnly && isDetailProduct)"
              :inputValue="productInfoState.unit_price"
              :isNumberType="true"
              :isReadOnly="isReadOnly && isDetailProduct"
              :inputICP="true"
              class="product-informations-field-input"
              @update:inputValue="handleUpdateProduct($event, 'unit_price')"
            />
            <input-disable v-else :text="formatNumber(productInfoState.unit_price)" />
          </div>
          <v-select
            :menu-props="{ contentClass: 'select-menu export-select z-index-high' }"
            class="select-default list-menu--select select-item unit-price product-price"
            solo
            fat
            :items="currencyUnitList"
            v-model="productInfoState.currency"
            :disabled="(!canEditProductInfo || isReadOnly) && isDetailProduct"
          ></v-select>
        </div>
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_production_volume') }}</div>
        </div>

        <div class="date-select-group">
          <v-select
            :menu-props="{ contentClass: 'select-menu export-select z-index-high' }"
            class="select-default list-menu--select select-item unit-price product-quantity"
            solo
            fat
            :items="timePeriodProductionVolume"
            v-model="productInfoState.period_type"
            item-text="text"
            item-value="key"
            :disabled="(!canEditProductInfo || isReadOnly) && isDetailProduct"
          ></v-select>
          <div class="unit-price text-left product-quantity">
            <input-field
              v-if="!(isReadOnly && isDetailProduct)"
              :inputValue="productInfoState.periodic_quantity"
              :isNumberType="true"
              :isReadOnly="isReadOnly && isDetailProduct"
              :inputICP="true"
              class="product-informations-field-input"
              @update:inputValue="handleUpdateProduct($event, 'periodic_quantity')"
            />
            <input-disable v-else :text="formatNumber(productInfoState.periodic_quantity)" />
          </div>
          <div v-if="productUnitCondition" class="product-informations-field-title product-informations__currency-unit-label">
            {{ productInfoState.unit }}
          </div>
        </div>
      </div>

      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_annual_sales') }}</div>
        </div>
        <div class="product-informations__annual-revenue date-select-group">
          <input-disable :text="formatValueNumberFullData(annualRevenue)" class="unit-price" />
          <div class="product-informations-field-title product-informations__currency-unit-label">
            {{ productInfoState.currency }}
          </div>
        </div>
      </div>
    </div>

    <!-- product date info -->
    <div class="product-informations-form name">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_start_date') }}</div>
        </div>
        <text-field-date
          :key="key"
          :isDisabled="
            ((!canEditProductInfo || isReadOnly) && isDetailProduct) || (!productInfoState.lotNumber && !isReadOnly)
          "
          :inputValue="productInfoState.startDate"
          @update:inputValue="handleUpdateProduct($event, 'startDate')"
        />
      </div>
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_end_date') }}</div>
        </div>
        <text-field-date
          :key="key"
          :isDisabled="
            ((!canEditProductInfo || isReadOnly) && isDetailProduct) || (!productInfo.lotNumber && !isReadOnly)
          "
          :inputValue="productInfoState.endDate"
          @update:inputValue="handleUpdateProduct($event, 'endDate')"
        />
      </div>
    </div>

    <!-- location -->
    <div class="product-informations-form">
      <div class="product-informations-field" style="width: 100%">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_location') }}</div>
        </div>

        <location-table
          :isReadOnly="isReadOnly"
          :organizations="productInfoState"
          :isCheckValidate="isCheckValidate"
          @updateLayerBinding="updateLayerBinding"
          @updateOrganizations="handleUpdateOrganizations"
        />
      </div>
    </div>

    <div v-if="isShowEmissionBox" class="product-informations-form name register-date-box">
      <div class="product-informations-field register-date">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_register_date') }}</div>
        </div>
        <input-disable :text="productInfoState.created_at" />
      </div>
      <div class="product-informations-field note">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('reduction.label_note') }}</div>
        </div>
        <input-field
          v-if="isNoDisableNote"
          class="product-informations-field-input"
          @update:inputValue="updateNoteComment($event)"
          :inputValue="productInfoState.note"
        />
        <input-disable v-else :text="productInfoState.note" />
      </div>
    </div>

    <!-- update condition when has register emissions -->
    <div v-if="isShowEmissionBox" class="product-informations-form name note-box">
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_emission_per_product') }}</div>
        </div>
        <div class="product-emission">
          {{ formatValueNumberFullData(productInfoState.product_emissions)}}
          <span class="unit">{{ productInfoState.product_emissions_unit }}</span>
        </div>
      </div>
      <div class="product-informations-field">
        <div class="product-informations-field-title">
          <div class="text">{{ $t('cfp_emissions_product.label_emission_per_declared_unit') }}</div>
        </div>
        <div class="product-emission">
          {{ formatValueNumberFullData(productInfoState.declare_emissions) }}
          <span class="unit">{{ productInfoState.declare_emissions_unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
import InputField from '@/components/products/input/InputField';
import InputDisable from '@/components/products/table/InputDisable';
import LocationTable from '@/components/products/LocationTable';
import TextFieldDate from '@/components/products/input/TextFieldDate';
import { getProductInfoByParams } from '@/api/product';

import debounce from 'lodash/debounce';
import { formatBigNumberToString } from '@/utils/number';
import { STATUS_FIELD } from '@/constants/status';
import { ROLE } from '@/constants/role';
import i18n from '@/lang/i18n';
import { mapState } from 'vuex';
import Tooltip from '@/components/commonApp/Tooltip';
import { formatNumberRealNum, formatNumberByConditions, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getStartMonth } from '@/api/duration';
import { $_helper_isNumberType_bySetting, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import InfiniteScrollIncludeSearch from '@/components/pulldown/InfiniteScrollIncludeSearch';

export default {
  name: 'ProductInfo',
  components: {
    InfiniteScroll,
    InputField,
    InputDisable,
    Tooltip,
    LocationTable,
    TextFieldDate,
    InfiniteScrollIncludeSearch,
  },
  props: {
    declaredUnitList: {
      type: Array,
      default: () => [],
    },
    productInfo: {
      type: Object,
      default: () => {},
    },
    canEditProductInfo: {
      type: Boolean,
      default: false,
    },
    isHasEmissionVal: {
      type: Boolean,
      default: false,
    },
    isDetailProduct: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: true,
    },
    isDraftProduct: {
      type: Boolean,
      default: false,
    },
    workflowData: {
      type: Object,
      default: () => {},
    },
    isCheckValidate: {
      type: Boolean,
      default: false,
    },
    categoryListProduct: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    isShowEmissionBox() {
      return this.productInfo?.product_id;
    },
    isDisabledNote() {
      return this.productInfo?.product_id && this.productInfo?.status !== STATUS_FIELD.STATUS_DRAFT;
    },
    isNoDisableNote() {
      return (
        this.productInfo?.product_id &&
        (this.productInfo?.is_tmp === 1 ||
          ([STATUS_FIELD.STATUS_DRAFT, STATUS_FIELD.STATUS_REPRENSENT_RETURN, STATUS_FIELD.STATUS_RETURN].includes(
            this.productInfo?.status,
          ) &&
            this.productInfo?.is_tmp === 0 &&
            this.productInfo?.is_owner === 1) ||
          (this.isApproveProductInfoInEmission &&
            this.currentUser?.user?.role_id === ROLE.ADMIN &&
            [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.productInfo?.status)))
      );
    },
    isApproveProductInfoInEmission() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(
        this.productInfo?.product_status,
      );
    },
    isDisabledDate() {
      return (
        ((!this.canEditProductInfo || this.isReadOnly) && this.isDetailProduct) ||
        (!this.productInfo.lotNumber && !this.isReadOnly)
      );
    },
    isDisabledUseCFPInOtherProducts() {
      return (!this.canEditProductInfo || this.isReadOnly) && this.isDetailProduct;
    },
    labelSelectYear() {
      return this.$t('new_cfp.placeholder_select_year');
    },
    labelSelectMonth() {
      return this.$t('new_cfp.placeholder_select_month');
    },
    annualRevenue() {
      const { period_type, unit_price, periodic_quantity } = this.productInfoState;

      if ($_helper_isNumberType_bySetting(unit_price) && $_helper_isNumberType_bySetting(periodic_quantity)) {
        const countMonth = period_type === 'monthly' ? 12 : 1;
        const price = math.bignumber(this.productInfoState.unit_price);
        const quantity = math.bignumber(this.productInfoState.periodic_quantity);
        return formatBigNumber(math.evaluate(`${price.toString()} * ${quantity.toString()} * ${countMonth}`), 50);
      }
      return null;
    },
    productUnitCondition() {
      return this.productInfoState.unit?.length < 11
    },
  },
  data() {
    return {
      isCheckLang: i18n.locale,
      declaredUnit: null,
      isMappingNameCode: false,
      key: null,
      productErrorMessages: {
        productUnitErrorMessages: [],
        productQuantityErrorMessages: [],
        productDeclareUnitErrorMessage: '',
      },
      productInfoState: {
        name: '',
        code: '',
        unit: '',
        declareUnit: '',
        product_quantity: null,
        lotNumber: '',
        startDate: null,
        endDate: null,
        organizational_division: null,
        company_name: null,
        business_name: null,
        country: null,
        layer_3: null,
        layer_4: null,
        layer_5: null,
        layer_6: null,
        branch_id: null,
        month: '',
        year: '',
        is_reuse: 1,
        category: '',
        unit_price: null,
        currency: null,
        period_type: 'yearly',
        periodic_quantity: null,
        annual_revenue: null,
      },
      productDate: {
        monthsList: [],
        yearsList: [],
        startMonth: null,
      },
      useCFPInOtherProducts: [
        {
          text: this.$t('new_cfp.text_select_use_cfp_in_other_products'),
          value: 1,
        },
        {
          text: this.$t('new_cfp.text_select_not_use_cfp_in_other_products'),
          value: 0,
        },
      ],
      currencyUnitList: [
        {
          id: 1,
          text: 'JPY',
          value: 'JPY',
        },
        {
          id: 2,
          text: 'USD',
          value: 'USD',
        },
        {
          id: 3,
          text: 'EUR',
          value: 'EUR',
        },
        {
          id: 4,
          text: 'CNY',
          value: 'CNY',
        },
        {
          id: 5,
          text: 'GBP',
          value: 'GBP',
        },
        {
          id: 6,
          text: 'KRW',
          value: 'KRW',
        },
      ],

      timePeriodProductionVolume: [
        {
          id: 1,
          key: 'monthly',
          text: this.$t('new_cfp.text_select_month'),
        },
        {
          id: 2,
          key: 'yearly',
          text: this.$t('new_cfp.text_select_year'),
        },
      ],
    };
  },
  mounted() {
    this.getDuration();
  },
  methods: {
    getLabelDetail(val) {
      if (val) return '';
      return this.isDetailProduct && this.isReadOnly ? this.$t('cfp_emissions_product.error_not_set') : '';
    },

    // handleUpdateProduct: debounce(function (newData, keyData) {
    //   if (newData !== this.productInfo[keyData]) {
    //     this.productInfoState[keyData] = newData;
    //     this.$emit('updateProductInfo', {
    //       ...this.productInfoState,
    //       code: this.productInfoState.code.trim(),
    //     });
    //   }
    //   if (keyData === 'unit' && newData === '') this.resetErrorMessage('productUnitErrorMessages');
    // }, 100, true),
    handleUpdateProduct(newData, keyData) {
      if (newData !== this.productInfo[keyData]) {
        this.productInfoState[keyData] = newData;
        this.$emit('updateProductInfo', {
          ...this.productInfoState,
          code: this.productInfoState.code.trim(),
        });
      }
      if (keyData === 'unit' && newData === '') this.resetErrorMessage('productUnitErrorMessages');
    },
    async autofillCodeField() {
      // CALL API get product code by product name
      const { name } = this.productInfoState;
      if (name?.trim()) {
        try {
          const res = await getProductInfoByParams({ name });
          this.productInfoState.code = res.data?.code || this.productInfoState.code;
          this.isMappingNameCode = Boolean(res.data?.code);
        } catch (error) {
          if (error.status === 403) {
            this.$emit('showErrorMessage', error.message);
          }
          console.error('Failed to fetch product info:', error);
        }
      }
    },
    clearDataField() {
      // TODO: Backup for 21119
      // this.productInfoState.code = '';
    },

    updateDeclareUnit(newVal) {
      this.productInfoState.declareUnit = newVal;
      this.$emit('updateProductInfo', this.productInfoState);
    },
    handleCheckProductUnit(productUnit) {
      if (productUnit.length > 10) {
        this.productErrorMessages.productUnitErrorMessages.push(
          this.$t('cfp_emissions_product.error_maximum_10_charactors_unit'),
        );
      }
    },
    handleCheckProductQuantity(productQuantity) {
      this.productErrorMessages.productQuantityErrorMessages = [];
      if (!this.isDraftProduct && this.isDetailProduct) return;
      if (this.isDetailProduct && !productQuantity) {
        this.productErrorMessages.productQuantityErrorMessages.push(this.$t('cfp_emissions_product.error_not_set'));
      } else {
        this.productErrorMessages.productQuantityErrorMessages = [];
      }
    },
    handleCheckProductDeclareUnit(declareUnit) {
      if (!this.isDraftProduct && this.isDetailProduct) return;
      if (this.isDetailProduct && !declareUnit) {
        this.productErrorMessages.productDeclareUnitErrorMessage = this.$t('cfp_emissions_product.error_not_set');
      } else {
        this.productErrorMessages.productDeclareUnitErrorMessage = '';
      }
    },
    updateNoteComment(newVal) {
      this.productInfoState.note = newVal;
    },
    formatBigNumber(num) {
      return formatBigNumberToString(num);
    },
    formatNumber(num) {
      return formatNumberRealNum(num) || '---';
    },
    resetErrorMessage(type) {
      this.productErrorMessages[type] = type === 'productDeclareUnitErrorMessage' ? '' : [];
    },
    handleUpdateOrganizations(organizations, branchData, layerBinding) {
      this.productInfoState = {
        ...this.productInfoState,
        ...organizations,
        branch_id: branchData?.id || null,
      };
      this.$emit('updateProductInfo', this.productInfoState, layerBinding);
    },
    updateLayerBinding(layerList) {
      this.$emit('updateLayerBinding', layerList);
    },
    async getDuration() {
      await getStartMonth(this.$store.state.userData.contractor).then((res) => {
        this.productDate.startMonth = res.data.start_month;
        this.productDate.yearsList = calcYearFromStartMonthCustome(res.data.start_month, 2017);
        this.productDate.monthsList = getMonthListCustome(null, res.data.start_month);
      });
    },
    selectedYearsList(value) {
      this.productDate.monthsList = getMonthListCustome(value, this.productDate.startMonth);
      if (
        this.productInfoState.month &&
        this.productDate.monthsList.findIndex((item) => item.value === this.productInfoState.month) < 0
      ) {
        this.productInfoState.month = '';
      }
    },
    updateProductCategory(categoryData) {
      this.productInfoState.category = categoryData;
    },
    formatValueNumberFullData(number) {
      if (!number) return '---';
      if (number === '0') return '0.00';
      return formatNumberByConditions(
        addThoundSandCommaWithTwoDigit(formatBigNumber(number, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true),
        {
          isRealNumber: true,
          isGetMoreZeroInDecimalPart: true,
        },
      );
    },
  },
  watch: {
    productInfo: {
      handler(newVal) {
        this.productInfoState = newVal;
        if (this.isDetailProduct) {
          this.handleCheckProductQuantity(newVal?.product_quantity);
          this.handleCheckProductDeclareUnit(newVal?.declareUnit);
        }
      },
      deep: true,
      immediate: true,
    },
    productInfoState: {
      handler(newVal) {
        if (newVal.declareUnit) {
          this.handleCheckProductDeclareUnit(newVal.declareUnit);
        }
        // Re-render get startDate & endDate in case data disabled
        if (
          (newVal.startDate || newVal.endDate) &&
          (!this.canEditProductInfo || this.isReadOnly) &&
          this.isDetailProduct
        ) {
          this.key++;
        }
        if (newVal.year) {
          this.selectedYearsList(newVal.year);
        }
      },
      deep: true,
    },
    isReadOnly: {
      async handler(value) {
        if (!value) {
          const { name } = this.productInfoState;
          if (name?.trim()) {
            try {
              const res = await getProductInfoByParams({ name });
              const existCode = Boolean(res.data?.code);
              this.isMappingNameCode = existCode && this.productInfoState.code === res.data?.code;
            } catch (error) {
              console.error('Failed to fetch product info:', error);
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.product-informations {
  display: flex;
  flex-flow: column;
  background: $bgCusLight;
  margin: 0 -20px;

  &-header {
    color: $monoWhite;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.42px;
    height: 44px;
    padding: 8px 20px;
    gap: 16px;
    background: $bgDeep;
  }
  &-form {
    padding: 16px 40px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    .product-informations-field {
      display: flex;
      flex-flow: column;
      gap: 8px;
      min-height: 72px;
      justify-content: space-between;
      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-style: normal;
        font-weight: 500;
        .text {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.42px;
          font-weight: 500;
          color: $monoBlack;
        }
        .required-text {
          background: $goldMid;
          color: $monoWhite;
          border-radius: 4px;
          display: flex;
          padding: 0px 4px 2px 4px;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.33px;
          width: max-content;
          height: 20px;
        }
      }
      .product-informations-field-input {
        border: none;
      }
    }
    &.name {
      .product-informations-field {
        .product-emission {
          font-family: 'Century Gothic Pro';
          font-size: 34px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0.03em;
          color: $goldMid;
          word-break: break-all;
          .unit {
            display: inline-block;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.05em;
          }
        }
        .product-no-support {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.42px;
          color: $monoBlack;
        }
      }
    }
    &.lot-number {
      .product-informations-field {
        width: 100%;
      }
    }
  }
  &.boundary {
    background: $bgExtraLight;
  }
  &__annual-revenue {
    display: flex;
    gap: 10px;
    .unit-price {
      min-width: 0;
      flex: 1 1 auto
    }
    .product-informations__currency-unit-label {
      min-width: 0;
      flex: 0 0 auto
    }
  }

  &__currency-unit-label {
    color: $monoMid;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}

@include desktop {
  .product-informations {
    display: flex;
    flex-flow: column;
    background: $bgCusLight;
    margin: 0;

    &-header {
      color: $monoWhite;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.42px;
      height: 44px;
      padding: 8px 20px;
      gap: 16px;
      background: $bgDeep;
    }
    &-form {
      flex-direction: row;
      padding: 16px 40px 22px;
      gap: 20px;
      width: 100%;
      &.name {
        .product-informations-field {
          width: calc((100% - 20px) / 2);
        }
        &.note-box {
          padding: 16px 40px 24px 40px;
        }
        &.register-date-box {
          .product-informations-field {
            &.register-date {
              width: calc((100% - 40px) / 3);
            }
            &.note {
              width: calc(100% - ((100% - 40px) / 3));
            }
          }
        }
      }

      &.unit {
        .product-informations-field {
          width: calc((100% - 40px) / 3);
        }
      }
    }
    &.boundary {
      background: $bgExtraLight;
    }
  }
}
.date-select-group {
  display: flex;
  gap: 8px;
  .v-input {
    width: 238px;
  }

  .text-left {
    text-align: left;
  }

  .unit-price {
    width: 100%;
    text-align: right;
  }
  .product-quantity {
    width: calc(100% / 3);
    flex: 1;
  }
  .product-informations__currency-unit-label {
    flex-shrink: 0;
  }
  .product-price {
    flex: 1;
    min-width: 0;
  }
}

@media screen and (max-width: 768px) {
  .date-select-group {
    .v-input {
      width: 100%;
    }
  }
}
::v-deep .infinite-scroll .input-field {
  background: $monoOffWhite;
}
::v-deep .select-use-cfp .v-select__selections input,
::v-deep .date-select-group .v-select__selections input {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}
::v-deep .select-use-cfp .v-select__selections input::placeholder,
::v-deep .date-select-group .v-select__selections input::placeholder {
  color: $monoMid;
}
</style>
