<template>
  <v-dialog
    v-model="getDialog"
    @click:outside="handleClickOutSite"
    ref="dialog"
    max-width="464px"
    :content-class="checkScroll"
  >
    <v-card>
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text :class="checkAddClass">
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="buttons"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isStyleForCardText: {
      type: Boolean,
      default: true,
    },
    isHiddenScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    checkAddClass() {
      return this.isStyleForCardText ? 'pt-4' : 'removePadding';
    },
    checkScroll() {
      return this.isHiddenScroll ? '' : 'hidden-scroll';
    },
  },
  methods: {
    close() {
      if (this.isLoading) {
        return;
      }
      this.$emit('close');
    },
    handleClickOutSite() {
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/components/popup/index.scss';
::v-deep {
  .v-card__title {
    display: flex;
    gap: 12px;
    padding: 16px 32px !important;
    img {
      display: flex;
      width: 28px;
      height: 28px;
      padding: 0.5px;
      justify-content: center;
      align-items: center;
    }
    .title-text {
      color: $monoBlack;
      font-family: 'Source Han Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
  }
  .v-card__text {
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .removePadding {
    padding: 0 !important;
  }
  .v-card__actions {
    display: flex;
    gap: 8px;
    width: 100%;
  }
  .hidden-scroll {
    overflow-y: unset;
  }
}
</style>
