import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberByConditions } from '@/utils/convertNumber';

export const formatBigNumberToString = (num, isAlowMore25digit = false, isRoundNumber= false) => {
  if(num === '-') {
    return '---'
  }
  if(num === 0 || num === '0') {
    return '0.00'
  }
  return formatNumberByConditions(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), { decimalPlaces: 2, isAlowMore25digit: isAlowMore25digit, isRoundNumber: isRoundNumber  });
}