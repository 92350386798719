<template>
  <div class="emissions-parent">
    <div v-for="(item, index) in emissionsList" :key="index" :ref="'product_' + index" class="emissions-product">
      <div class="emissions-product_date" :class="$i18n.locale">
        {{ item.updated_at }}
        <div class="status" :class="item.colorStatus">{{ item.status }}</div>
      </div>
      <div class="emissions-product_note">
        {{ $t('cfp_emissions_product.note_emissions_product', { note: item.note }) }}
      </div>
      <!-- <div class="emissions-product_empty"></div> -->
      <div class="emissions-product_detail">
        <div class="emissions-product_detail--label">{{ $t('cfp_emissions_product.label_emission_per_product') }}</div>
        <div class="emissions-product_detail--number">
          {{ item.product_emissions }}
          <span class="unit-warp">
            kg-CO2e<span class="unit">/{{ workflowData.product_unit }}</span>
          </span>
        </div>
      </div>
      <button-custome
        icon="eye.svg"
        iconHover="eye-hover.svg"
        :isSmallButton="false"
        :buttonText="$t('cfp_emissions_product.button_edit_product_information')"
        @action="redirectToEmissionsDetail(item)"
      >
      </button-custome>
    </div>
  </div>
</template>

<script>
import ButtonCustome from '@/components/commonApp/ButtonIconCustom';

import { STATUS_FIELD } from '@/constants/status';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { getProductEmissionsList } from '@/api/product/emissionsProduct';
import { getColorTextStatusById, getStatusNameById } from '@/utils/status';
import { formatNumberByConditions, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
export default {
  name: 'EmissionProductInfo',
  components: { ButtonCustome },
  props: {
    workflowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      emissionsList: [],
    };
  },
  mounted() {
    this.getEmissionsList();
  },
  methods: {
    redirectToEmissionsDetail(item) {
      const newQuery = {
        ...this.$route.query,
      };
      this.$router
        .push({ name: 'EmissionsDetailProduct', params: { emissionId: item.id }, query: newQuery })
        .catch(() => {});
    },
    getStatusAnfColorByType(item) {
      const typeQuery = this.$route.query?.type;
      let status = '';
      let color = '';

      status = getStatusNameById(item.status);
      color = getColorTextStatusById(item.status);
      if (typeQuery === 'approval') {
        if (item.status === STATUS_FIELD.STATUS_SUBMIT) {
          status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
          color = getColorTextStatusById(STATUS_FIELD.STATUS_REPRENSENT_RETURN);
        }

        if (item.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
          status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
        }
      }

      return { status, color };
    },
    async getEmissionsList() {
      try {
        const response = await getProductEmissionsList(this.workflowData.id);
        response.data.forEach((item) => {
          const { status, color } = this.getStatusAnfColorByType(item);
          this.emissionsList.push({
            ...item,
            updated_at: formatDateTimeDataTable(item.updated_at),
            status: status,
            colorStatus: color,
            product_emissions:
              item.product_emissions === null ? '---' : `${this.formatValueNumberFullData(item.product_emissions)}`,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
    formatValueNumberFullData(number) {
      if (!number) return '---';
      if (number === '0') return '0.00';
      return formatNumberByConditions(
        addThoundSandCommaWithTwoDigit(formatBigNumber(number, 25).replace(/[^0-9\.\-]+/g, ''), '', false, true),
        {
          isRealNumber: true,
          isGetMoreZeroInDecimalPart: true,
        },
      );
    },
  },
  watch: {
    emissionsList(newVal) {
      if (newVal.length <= 0) return;
      this.$nextTick(() => {
        let maxWidth = 0;
        for (let index = 0; index < newVal.length; index++) {
          const element = this.$refs['product_' + index][0];
          const widthOffElement = element.offsetWidth;
          if (widthOffElement > maxWidth) {
            maxWidth = widthOffElement;
          }
        }

        for (let index = 0; index < newVal.length; index++) {
          const element = this.$refs['product_' + index][0];
          element.style.width = `${maxWidth}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.emissions-parent {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: unset;
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 4px;
    z-index: 1000;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $bgMid;
  }

  &::-webkit-scrollbar-corner {
    background: $bgMid;
  }
}
.emissions-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  padding: 16px 40px 20px 40px;
  align-self: stretch;
  background: $bgLight;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
  color: $monoBlack;
  // border-bottom: 1px solid $dividerBlack;

  &_detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    &--number {
      font-family: 'Century Gothic Pro';
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 1.02px;
      color: $goldMid;
      text-wrap: wrap;
      word-break: break-all;
      .unit-warp {
        font-family: 'Century Gothic Pro';
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 1.02px;
        color: $goldMid;
        white-space: nowrap;
        .unit {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.7px;
          margin-left: 8px;
          white-space: nowrap;
        }
      }
    }
  }

  &_empty {
    display: none;
  }

  &_date {
    display: flex;
    flex-flow: row;
    gap: 10px;
    .status {
      background: unset !important;
      &.red-text {
        color: $redMid;
      }
      &.blue-text {
        color: $blueDark2;
      }
      &.mono-text {
        color: $monoMid;
      }
    }
  }
}

@include desktop {
  .emissions-parent {
    max-height: 400px;
  }
  .emissions-product {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 0;
    width: max-content;
    min-width: 100%;
    &_detail {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      min-width: fit-content;
      margin-left: 40px;
      // margin-right: 40px;
      &--label {
        min-width: 150px;
      }
    }
    &_date {
      min-width: 240px;
      width: 240px;
      &.en {
        min-width: 350px;
      }
    }
    &_note {
      min-width: 200px;
      width: 200px;
      margin-left: 40px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .button-custom {
      width: max-content;
      min-width: 75px;
      margin-left: auto;
    }
  }
}
</style>
