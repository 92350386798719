const CFP_PRODUCT_PROCESS_EMISSIONS = '/process-emission/cfp-product';
const CSV_TEMPLATE = 'download-template';
import request, { getFileName } from './base/request';

export const getCsvCfpTemplate = () => {
  return request
    .get(`${CFP_PRODUCT_PROCESS_EMISSIONS}/${CSV_TEMPLATE}`, { responseType: 'blob' })
    .then((res) => {
      const currentFileName = getFileName();
      return { data: res, fileName: currentFileName };
    })
    .catch((err) => {
      throw err;
    });
};
