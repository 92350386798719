<template>
  <div>
    <div
      class="process-item"
      :class="getModeRender"
      :ref="getRefProcess"
      :draggable="disableBoundaryInput || disableDraggable ? false : true"
      :style="getStyleByDepthAndMode"
      :id="process.idNode"
    >
      <div class="process-item-input" :style="getStyleCustom">
        <svg
          v-if="!isBoundaryForEmission"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.5 7H11.5V9H9.5V7Z" fill="#99A6A9" />
          <path d="M12.5 7H14.5V9H12.5V7Z" fill="#99A6A9" />
          <path d="M9.5 11H11.5V13H9.5V11Z" fill="#99A6A9" />
          <path d="M12.5 11H14.5V13H12.5V11Z" fill="#99A6A9" />
          <path d="M9.5 15H11.5V17H9.5V15Z" fill="#99A6A9" />
          <path d="M12.5 15H14.5V17H12.5V15Z" fill="#99A6A9" />
        </svg>
        <div class="underscore" :style="'width:' + getWidthByPosition + 'px'"></div>
        <div class="process-item-input--right" :class="isBoundaryForEmission && selectedMode === 1 && 'pl-2'">
          <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.84 6.48V0.319999H2.16V5.16H7V6.48H0.84Z" fill="#99A6A9" />
          </svg>

          <svg
            @click="handleToggleChildren"
            v-if="process.children && process.children.length > 0 && selectedMode === 2"
            :class="process.isOpen ? 'open' : 'close'"
            class="process-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 11.5L13 4.5H3L8 11.5Z" fill="#6B838F" stroke="#6B838F" stroke-linejoin="round" />
          </svg>
          <input-field-small
            v-if="isInRegisterProduct && !process.ref_cfp_product_id"
            v-model="process.name"
            :inputValue="process.name"
            class="process-input"
            :label="labelProcessInput"
            :isReadOnly="disableBoundaryInput"
            :class="process.children && process.children.length > 0 && ''"
            @focus="handleFocusInput('process')"
            @blur="handleBlurInput"
          />
          <approval-product-btn
            v-if="isInRegisterProduct && process.ref_cfp_product_id"
            :product="process"
            @handleApprovedProductPopup="handleApprovedProductPopup"
          />

          <input-disable
            v-if="isInRegisterEmission && !process.ref_cfp_product_id"
            :text="process.name"
            isSmallInput
            class="item-name disable"
          />
          <approval-product-btn
            v-if="isInRegisterEmission && process.ref_cfp_product_id"
            :product="process"
            @handleApprovedProductPopup="handleApprovedProductPopup"
          />
        </div>

        <div class="process-quantity ml-4">
          <div class="process-label">{{ $t('new_cfp.header_option_quantity') }}</div>
          <input-field-small
            v-if="!isBoundaryForEmission && !disableBoundaryInput"
            v-model="process.quantity"
            class="quantity-input"
            :inputValue="process.quantity"
            :isReadOnly="disableBoundaryInput"
            :isNumberType="true"
            :isNumberInterger="true"
            :cutNumberObj="{
              isCut: true,
              maxCharactor: 25,
            }"
            @focus="handleFocusInput('process')"
            @blur="handleBlurInput"
          />
          <input-disable
            v-if="disableBoundaryInput"
            :text="process.quantity"
            isSmallInput
            :idNode="getRefProcess"
            :isNumberType="true"
            class="quantity-input"
            :class="isBoundaryForEmission && 'disable'"
          />
        </div>
      </div>

      <div class="process-action" v-if="!isBoundaryForEmission">
        <div
          v-if="!isBoundaryForEmission && isShowCutOff"
          class="process-cutoff-label"
          :class="{ disabled: disableBoundaryInput }"
        >
          <div class="checkbox-container">
            <input
              :id="process.name"
              type="checkbox"
              :name="process.name"
              v-model="process.cut_off"
              :disabled="disableBoundaryInput"
              :class="{ disabled: disableBoundaryInput }"
              @change="updateCutoffProcessItem(boundaryIndex, processIndex)"
              @mousedown="updateCutoffProcessItem(boundaryIndex, processIndex)"
            />
            <span class="checkmark"></span>
          </div>
          <label class="label" :for="process.name">{{ $t('product_boundary.checkbox_process_cutoff') }}</label>
        </div>
        <button-custom
          v-if="
            !isBoundaryForEmission && !disableBoundaryInput && depth < getMaxLayer - 1 && !process.ref_cfp_product_id
          "
          icon="addregistericon.svg"
          iconHover="addregistericon_active.svg"
          iconDisable="addregistericon_disable.svg"
          typeIcon="icons"
          :isSmallButton="true"
          class="process-delete"
          :buttonText="$t('new_cfp.button_add_sub_item')"
          :isDisable="disableBoundaryInput"
          @action="handleAddSubItem(boundaryIndex, processIndex)"
        >
        </button-custom>
        <button-custom
          v-if="!isBoundaryForEmission && !disableBoundaryInput"
          icon="products/process-delete.svg"
          iconHover="products/process-delete-hover.svg"
          iconDisable="products/process-delete-disable.svg"
          typeIcon="image"
          :isSmallButton="true"
          class="process-delete"
          :buttonText="deleteBtn"
          :isDisable="disableBoundaryInput"
          @action="showQuestionPopup()"
        >
        </button-custom>
      </div>

      <!-- case disableBoundaryInput -->
      <div v-if="isBoundaryForEmission" class="process-action-emission">
        <emission-information
          :isSubProcess="!!depth"
          :process="process"
          :workflowData="workflowData"
          :selectedMode="selectedMode"
          :isProcessItem="true"
        />
      </div>
    </div>
    <add-a-lower-level-popup
      v-if="openDialogAddALowerLevelPopup"
      :dialog="openDialogAddALowerLevelPopup"
      :approvedProductList="approvedProductList"
      @close="openDialogAddALowerLevelPopup = false"
      @submit="handleSubmitAddSubItem"
    ></add-a-lower-level-popup>
    <question-popup
      v-if="questionPopup.dialog"
      :confirm="true"
      :dialog="questionPopup.dialog"
      :message="questionPopup.message"
      :isSaveButtonDelete="true"
      :confirmText="$t('popup.button_delete')"
      @close="questionPopup.dialog = false"
      @submit="handleDeleteProcessItem()"
    />
  </div>
</template>

<script>
// UI import
import ButtonCustom from '@/components/commonApp/ButtonIconCustom';
import InputDisable from '@/components/products/table/InputDisable';
import InputFieldSmall from '@/components/products/input/InputFieldSmall';
import ApprovalProductBtn from '@/components/products/ApprovalProductBtn';
import AddALowerLevelPopup from '@/components/dialogs/add-a-lower-level-popup';
// import EmissionPerProduct from '@/components/products/table/EmissionPerProduct';
import EmissionInformation from '@/components/products/common/emission-information.vue';

import { MAX_LAYER } from '@/constants/products/define-data';
import QuestionPopup from '@/components/dialogs/question-popup';

export default {
  name: 'ProcessItem',
  components: {
    InputFieldSmall,
    InputDisable,
    // EmissionPerProduct,
    ButtonCustom,
    ApprovalProductBtn,
    AddALowerLevelPopup,
    EmissionInformation,
    QuestionPopup,
  },
  props: {
    isBoundaryForEmission: {
      type: Boolean,
      default: false,
    },
    disableBoundaryInput: {
      type: Boolean,
      default: false,
    },
    process: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    processLength: {
      type: Number,
      default: 0,
    },
    isShowCutOff: {
      type: Boolean,
      default: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    boundaryIndex: {
      type: Number,
      default: 0,
    },
    processIndex: {
      type: Number,
      default: 0,
    },
    indexInTree: {
      type: Number,
      default: 0,
    },
    selectedMode: {
      type: Number,
      default: 1,
    },
    workflowData: {
      type: Object,
      default: () => {},
    },
    approvedProductList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opacity: 1,
      openDialogAddALowerLevelPopup: false,
      widthProcess: 0,
      disableDraggable: false,
      questionPopup: {
        dialog: false,
        message: this.$t('cfp_emissions_product.message_delete_process'),
      },
    };
  },
  computed: {
    deleteBtn() {
      return this.selectedMode === 2 ? this.$t('product_boundary.button_delete') : '';
    },
    labelProcessInput() {
      return this.$t('new_cfp.placeholder_boundary_process');
    },
    isHasSubItem() {
      return this.index < this.processLength - 1;
    },
    getWidthByPosition() {
      return 12 * (this.depth * 2 + 1) + 12;
    },
    getStyleByDepthAndMode() {
      if (this.selectedMode === 2) {
        return `display: ${!this.opacity ? 'none' : 'flex'}`;
      }

      return `margin-left: ${this.depth * 16}px;`;
    },
    getMaxLayer() {
      return MAX_LAYER;
    },
    getRefProcess() {
      return `process-${this.process?.idNode}`;
    },
    getModeRender() {
      let classList = '';
      classList += this.selectedMode === 1 ? 'card-list-item' : 'normal-list-item';
      if (this.depth == 0) {
        classList += ' process-depth';
      }
      if (this.isBoundaryForEmission) {
        classList += ' emissions-disable';
      }
      return classList;
    },
    getStyleCustom() {
      if (this.selectedMode === 1) return null;
      if (this.isBoundaryForEmission) {
        return `max-width: unset;`;
      }
      let maxWidth = 363 + this.getWidthByPosition - 12;
      return `max-width: ${maxWidth}px;`;
    },
    isInRegisterProduct() {
      return !this.isBoundaryForEmission && !this.disableBoundaryInput;
    },
    isInRegisterEmission() {
      return this.disableBoundaryInput || this.isBoundaryForEmission;
    },
  },
  mounted() {
    this.measureWidth();
  },
  methods: {
    measureWidth() {
      if (this.$refs[this.getRefProcess]) {
        this.widthProcess = this.$refs[this.getRefProcess].clientWidth;
      }
    },
    handleBlurInput() {
      this.disableDraggable = false;
      this.$emit('blurInput');
      this.$emit('handleChangeInput', this.process);
    },
    updateCutoffProcessItem() {
      this.$emit('handleChangeInput', this.process);
    },
    handleFocusInput() {
      this.disableDraggable = true;
      this.$emit('focusInput');
    },
    handleDeleteProcessItem() {
      this.$emit('deleteProcessItem', this.process);
      this.$nextTick(() => {
        this.questionPopup.dialog = false;
      });
    },
    handleSubmitAddSubItem(data) {
      this.$emit('addSubItem', this.process, data);
      this.openDialogAddALowerLevelPopup = false;
    },
    handleAddSubItem() {
      this.openDialogAddALowerLevelPopup = true;
    },
    handleToggleChildren() {
      this.$emit('toggleChildren', this.process);
    },
    showQuestionPopup() {
      this.questionPopup.dialog = true;
    },
    handleApprovedProductPopup(product) {
      this.$emit('handleApprovedProductPopup', product);
    },
  },
  watch: {
    selectedMode() {
      this.measureWidth();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-name {
  min-width: 157px;
  &.disable {
    width: 100%;
  }
}
.process-item {
  display: flex;
  flex-flow: column;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  background: $bgExtraLight;
  &-input {
    display: flex;
    align-items: center;
    padding-left: 4px;
    &--right {
      display: flex;
      min-width: 200px;
      align-items: center;
      gap: 8px;
    }
    .process-quantity {
      display: flex;
      align-items: center;
      gap: 8px;
      .quantity-input {
        width: 60px;
      }
    }
  }
  &.normal-list-item {
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    &.process-depth {
      background: $bgLight;
    }
    .process-delete {
      width: max-content;
      min-width: 61px;
      height: 28px !important;
      padding: 4px 8px 6px !important;
      margin: 0 auto;
      ::v-deep {
        .v-btn__content {
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0.33px;
          color: $monoBlack;
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 6px !important;
          }
        }
      }
    }
    .process-input {
      max-width: 157px;
    }
    .process-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
    &.emissions-disable {
      .process-action-emission {
        flex: 1;
      }
    }
  }
  &.card-list-item {
    flex-direction: column;
    min-height: 80px;
    // background: $bgLight;
    padding: 0;
    margin-left: auto;
    &.emissions-disable {
      min-height: 78px;
      .process-action-emission {
        background: $bgExtraLight;
      }
    }
    &.process-depth {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      background: $bgLight;
      margin-top: 0;
      max-height: 80px;
    }
    .process-item-input {
      display: flex;
      align-items: center;
      background: $bgExtraLight;
      padding: 8px 8px 8px 0px;
      gap: 6px;
      justify-content: space-between;
      .underscore {
        display: none;
      }
      .process-input {
        width: calc(100% - (40px));
      }
      .process-icon {
        &.open {
          display: none;
        }
      }
      &--right {
        display: flex;
        min-width: 180px;
        align-items: center;
        gap: 8px;
        width: calc(100% - (24px + 91px + 16px));
      }
      .process-quantity {
        display: flex;
        align-items: center;
        gap: 8px;
        .quantity-input {
          width: 60px;
        }
      }
    }
    .process-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      margin-bottom: auto;
      padding: 0px 8px 8px 8px;
    }
  }
  .process-cutoff-label {
    color: $monoBlack;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
    display: flex;
    align-items: center;
    gap: 6px;
    // margin: 8px 0 2px;
    .label {
      cursor: pointer;
      min-width: 110px;
    }
    .checkbox-container {
      display: flex;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 16px;
      height: 16px;
    }
    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        background-color: $blueMid;
        &::after {
          display: block;
        }
      }
      &:hover ~ .checkmark {
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
      &:checked:hover ~ .checkmark {
        border: 1px solid rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 4px 10px rgba(0, 0, 0, 0.15) inset;
      }
      &.disabled {
        stroke-width: 0.5px;
        stroke: rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
        background: $grayDark !important;
        &:hover {
          cursor: unset;
        }
      }
      &.disabled ~ .checkmark {
        opacity: 0.8;
        background: $grayDark;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $monoWhite;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
      border: 1px solid rgba(0, 0, 0, 0.25);
      &::after {
        content: '';
        position: absolute;
        left: 4px;
        top: 0px;
        width: 6px;
        height: 11px;
        border: solid $monoWhite;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        display: none;
      }
    }
    &.disabled {
      .label {
        cursor: unset;
      }
    }
  }
}
.process-icon {
  cursor: pointer;
  &.close {
    transform: rotate(-90deg);
  }
  &.open {
    transform: rotate(0deg);
  }
}
.process-label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.33px;
  min-width: 23px;
  color: $monoBlack;
}
.process-input,
.quantity-input {
  ::v-deep {
    .v-input {
      .v-input__control {
        min-height: 28px;
      }
    }
  }
}
</style>
