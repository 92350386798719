<template>
  <v-dialog
    v-model="dialogVisible"
    ref="dialog"
    :persistent="true"
    :retain-focus="retainFocus"
    content-class="modal-fullscreen width-100pc dialog-custom-fullscreen"
    transition="dialog-bottom-transition"
  >
    <div class="content-main dialog-content" :class="addStyleImportPreview">
      <div class="head-category-popup">
        <span class="log-confirm_text mr-auto">
          {{ titlePopup }}
        </span>
        <div class="list-emission-close" @click="closeDialog">
          <img class="head-image-dialog-popup mr-0 log-confirm--btn close" src="@/assets/images/dialog/close.svg" />
          <img
            class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
            src="@/assets/images/dialog/close_active.svg"
          />
          <div class="list-emission-close__text">
            {{ $t('b_export_report.button_close') }}
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    titlePopup: {
      type: String,
      default: '',
      required: true,
    },
    isStyleImportPreview: {
      type: Boolean,
      default: true,
    },
    retainFocus: { 
      type: Boolean,
      default: true,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    addStyleImportPreview() {
      return this.isStyleImportPreview ? '' : 'import-preview';
    },
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
      this.$emit('clearDataPopUp');
    },
  },
  beforeDestroy() {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.style.overflow = 'auto';
    this.closeDialog();
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .dialog-custom-fullscreen {
    width: 100vw;
    height: 100vh;
    padding: 0;
    min-width: 100vw;
    &.v-dialog {
      max-height: 100vh;
    }
  }

  .dialog-custom-fullscreen {
    width: 100vw;
    height: 100vh;
    padding: 0;
    min-width: 100vw;
    overflow: hidden;
  }

  .dialog-custom-fullscreen.v-dialog {
    max-height: 100vh;
    overflow: hidden;
  }

  .dialog-content {
    height: 100% !important;
    overflow-y: auto;
  }
  .modal-fullscreen {
    background: $bgExtraLight;
  }

  .modal-open {
    overflow: hidden;
  }
}

.modal-fullscreen {
  position: relative;
  max-width: 100%;

  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }

  .content-main {
    height: 100%;

    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }

    .head-category-popup {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      background: $bgExtraLight;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      border-bottom: solid 1px rgba(121, 134, 134, 0.12);

      .log-confirm_text {
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }

      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: $monoOffWhite;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;

        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }

        .close-active {
          display: none;
        }

        .close {
          display: block;
        }

        &:hover {
          background: $goldLight;

          .close-active {
            display: block;
          }

          .close {
            display: none;
          }

          .list-emission-close__text {
            color: $monoWhite;
          }
        }

        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;

          .close-active {
            display: none;
          }

          .close {
            display: block;
          }

          .list-emission-close__text {
            color: $monoBlack;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .head-category-popup {
      padding: 16px 20px !important;
    }
  }
}
.content-main.import-preview {
  @include tablet {
    display: block;
  }
}
</style>
