<template>
  <div class="button-custom" :class="getClassNameByProps" @mousedown="handleBtnClick">
    <img class="icon-default" :src="getIcon" alt="" />
    <img class="icon-hover" :src="getIconHover" alt="" />
    <img v-if="isDisable" class="icon-disable" :src="getIconDisable" alt="" />
    <div class="button-custom_text" :class="buttonText === '' && 'empty'">{{ buttonText }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isSmallButton: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconHover: {
      type: String,
      default: null,
    },
    iconDisable: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    typeIcon: {
      type: String,
      default: 'icons',
    },
  },
  computed: {
    getIcon() {
      if (this.typeIcon === 'icons') {
        return require(`@/assets/icons/${this.icon}`);
      }
      return require(`@/assets/images/${this.icon}`);
    },

    getIconHover() {
      if (this.typeIcon === 'icons') {
        return require(`@/assets/icons/${this.iconHover}`);
      }
      return require(`@/assets/images/${this.iconHover}`);
    },

    getIconDisable() {
      if (this.typeIcon === 'icons') {
        return require(`@/assets/icons/${this.iconDisable}`);
      }
      return require(`@/assets/images/${this.iconDisable}`);
    },

    getClassNameByProps() {
      let className = '';
      if (this.isSmallButton) {
        className += ' small-button';
      }
      if (this.isDisable) {
        className += ' disable-button';
      }
      return className;
    },
  },

  methods: {
    handleBtnClick() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-custom {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  width: max-content;
  cursor: pointer;
  height: 40px;
  gap: 0px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  opacity: 1;
  border: 1px solid $dividerBlack;
  background: $monoOffWhite;

  animation-timing-function: ease-out;
  animation-duration: 50ms;

  .button-custom_text {
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
    color: $monoBlack;
    margin-left: 6px;
    &.empty {
      margin-left: 0;
    }
  }

  &.small-button {
    height: 28px;
  }

  .icon-hover {
    display: none;
  }

  &:hover {
    border: 1px solid $dividerBlack;
    background: $goldLight;
    .button-custom_text {
      color: $monoWhite;
    }

    .icon-hover {
      display: block;
    }

    .icon-default {
      display: none;
    }
  }

  &.disable-button {
    cursor: text;
    pointer-events: none;
    background: $monoLight;
    .button-custom_text {
      color: $monoMid;
    }
    .icon-hover {
      display: none;
    }

    .icon-default {
      display: none;
    }
  }
}
</style>
