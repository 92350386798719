<template>
  <div class="action-boundary" v-if="isHideBtnBoundary">
    <button-custom
      v-if="isExportProcess"
      class="boundary-add"
      icon="products/import-csv-material.svg"
      iconHover="products/import-csv-material-hover.svg"
      :label="$t('new_cfp.button_export_process_emissions')"
      :isDisable="disabledBoundary"
      @action="handleClick('exportProcessHandler')"
    />
    <template v-if="showBoundaryButtons">
      <button-custom
        v-if="isShowAddBtn"
        class="boundary-add"
        icon="products/import-csv-material.svg"
        iconHover="products/import-csv-material-hover.svg"
        :label="$t('new_cfp.button_import_new_materials')"
        :isDisable="disabledBoundary"
        @action="handleClick('importCsvHandler')"
      />
      <button-custom
        class="boundary-add"
        icon="products/boundary-add.svg"
        iconHover="products/boundary-add-hover.svg"
        :label="$t('new_cfp.button_add_boundary')"
        :isDisable="disabledBoundary"
        @action="handleClick('addBoundaryItem')"
      />
    </template>
  </div>
</template>
<script>
import ButtonCustom from '@/components/commonApp/Button';

export default {
  name: 'actionBoundary',
  components: {
    ButtonCustom,
  },
  props: {
    disabledBoundary: {
      type: Boolean,
      default: false,
    },
    disableBoundaryInput: {
      type: Boolean,
      default: false,
    },
    boundariesList: {
      type: Array,
      default: () => [],
    },
    isExportProcess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isHideBtnBoundary() {
      return this.isExportProcess || !this.disableBoundaryInput
    },
    showBoundaryButtons() {
      return !this.disableBoundaryInput;
    },
    isShowAddBtn() {
      return this.boundariesList.length > 0;
    },
  },
  methods: {
    handleClick(value) {
      this.$emit('handleClickBtnBoundary', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.action-boundary {
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: flex-end;
  align-items: stretch;
  gap: 10px;
  align-self: stretch;
  @include desktop {
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 16px 16px 0px;
  }
}
</style>
