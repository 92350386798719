<template>
  <recycle-scroller
    class="chat-container"
    :items="flatChats"
    :item-size="useSizeField ? null : itemHeight"
    :size-field="useSizeField ? 'itemSize' : null"
    :style="{ height: treeContentHeight }"
    key-field="idNode"
    v-slot="{ item, index }"
  >
    <div
      class="chat-item"
      :draggable="isDraggable || disableBoundaryInput ? false : true"
      @dragstart="(event) => onDragStart(event, item)"
      @dragover.prevent
      @drop="onDrop(item)"
    >
      <process-item
        :process="item"
        :key="index"
        :disableBoundaryInput="disableBoundaryInput"
        :isShowCutOff="isShowCutOff"
        :depth="item.depth"
        :boundaryIndex="boundaryIndex"
        :indexInTree="index"
        :processIndex="processIndex"
        :selectedMode="selectedMode"
        :isBoundaryForEmission="isBoundaryForEmission"
        :workflowData="workflowData"
        :approvedProductList="approvedProductList"
        @toggleChildren="toggleProcessItem"
        @addSubItem="handleAddSubItem"
        @deleteProcessItem="handleDeleteProcessItem"
        @focusInput="handleFocusInput"
        @blurInput="handleBlurInput"
        @handleApprovedProductPopup="handleApprovedProductPopup"
        @handleChangeInput="handleChangeInput"
        :isProcess="true"
      />
    </div>
  </recycle-scroller>
</template>

<script>
import ProcessItem from '@/components/products/ProcessItem';
import { math } from '@/concerns/newRegisterData/wijmo.helper';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  components: { ProcessItem, RecycleScroller },
  props: {
    arrayProcessInBoundary: Array,
    disableBoundaryInput: {
      type: Boolean,
      default: false,
    },
    isShowCutOff: {
      type: Boolean,
      default: false,
    },
    boundaryIndex: {
      type: Number,
      default: 0,
    },
    processIndex: {
      type: Number,
      default: 0,
    },
    selectedMode: {
      type: Number,
      default: 1,
    },
    isBoundaryForEmission: {
      type: Boolean,
      default: false,
    },
    workflowData: {
      type: Object,
      default: () => {},
    },
    approvedProductList: {
      type: Array,
      default: () => [],
    },
    contentHeight: {
      type: Number,
      default: 500,
    },
  },

  data() {
    return {
      treeData: [],
      flatChats: [],
      draggedItem: null,
      isDraggable: false,
    };
  },
  computed: {
    itemHeight() {
      return this.selectedMode === 1 && !this.isBoundaryForEmission ? 88 : 45;
    },
    useSizeField() {
      return this.isBoundaryForEmission && this.selectedMode === 1
    },
    treeContentHeight() {
      let totalTreeContentHeight;

      if (this.useSizeField) {
        totalTreeContentHeight = (this.flatChats || []).reduce((sum, item) => sum + item.itemSize, 0);
      } else {
        totalTreeContentHeight = (this.flatChats || []).length * this.itemHeight;
      }

      if (totalTreeContentHeight > this.contentHeight) {
        return `${this.contentHeight}px`;
      }
      return 'auto';
    }
  },
  methods: {
    generateTree() {
      this.treeData = this.arrayProcessInBoundary;
      this.treeData = this.addParentId(this.arrayProcessInBoundary);
      if (this.isBoundaryForEmission) {
        this.treeData = this.totalEmissionProcessTree(this.treeData);
      }
      this.updateFlatAllTreeview();
    },
    addParentId(nodes, parentId = null) {
      nodes.forEach((node) => {
        node.parent_id = parentId;
        // Nếu có children, tiếp tục cập nhật parent_id cho con
        if (node.children && node.children.length > 0) {
          this.addParentId(node.children, node.idNode);
        }
      });

      return nodes; // Trả về cây đã được cập nhật
    },

    handleChangeInput(value) {
      this.treeData = this.replaceNode(this.treeData, value.idNode, value);
    },
    replaceNode(array, idNode, newObject) {
      const traverseAndReplace = (arr) => {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].idNode === idNode) {
            this.$set(arr, i, newObject);
            return true;
          }
          if (arr[i].children && arr[i].children.length) {
            if (traverseAndReplace(arr[i].children)) {
              return true;
            }
          }
        }
        return false;
      };
      traverseAndReplace(array);
      return array;
    },
    updateFlatAllTreeview() {
      this.flatChats = this.flattenChats(this.treeData);
      this.$forceUpdate();
    },
    flattenChats(treeData, depth = 0) {
      let result = [];
      for (let chat of treeData) {
        result.push({ 
          ...chat,
          depth,
          itemSize: chat.cut_off ? 86 : 100
        });
        if (chat.isOpen && chat.children.length) {
          result = result.concat(this.flattenChats(chat.children, depth + 1));
        }
      }
      return result;
    },
    totalEmissionProcessTree(tree) {
      const calculateEmissions = (node) => {
        if (!node.children || node.children.length === 0) {
          node.emission_processes_actual = node.emission_processes !== null
            ? node.emission_processes
            : null;
          return node.emission_processes_actual;
        }

        let totalChildEmissions = node.children.reduce((sum, child) => {
          const childEmissions = calculateEmissions(child);
          return childEmissions !== null ? math.evaluate(`${sum} + ${childEmissions}`) : sum;
        }, 0);

        node.emission_processes_actual = node.emission_processes !== null
          ? math.evaluate(`${node.emission_processes} + ${totalChildEmissions }`)
          : (totalChildEmissions ? totalChildEmissions : null);

        return node.emission_processes_actual;
      };

      return tree.map(node => {
        const newNode = { ...node, children: node.children ? [...node.children] : [] };
        calculateEmissions(newNode);
        return newNode;
      });
    },
    toggleProcessItem(item) {
      const findNodeById = (nodes, itemId) => {
        for (const node of nodes) {
          if (node.idNode === itemId) return node;
          if (node.children) {
            const found = findNodeById(node.children, itemId);
            if (found) return found;
          }
        }
        return null;
      };
      const targetNode = findNodeById(this.treeData, item.idNode);
      if (targetNode) {
        targetNode.isOpen = !targetNode.isOpen;
      }
      // refresh
      this.updateFlatAllTreeview();
    },
    onDragStart(event, item) {
      this.draggedItem = item;
      event.dataTransfer.setData(
        'application/json',
        JSON.stringify({
          draggedItem: item,
          boundaryIndex: this.boundaryIndex,
        }),
      );
    },
    onDrop(targetItem) {
        if (!this.draggedItem || this.draggedItem.idNode === targetItem.idNode) return;
      const flattenedTree = this.flattenChats(this.treeData);
      const targetFlatItem = flattenedTree.find((item) => item.idNode === targetItem.idNode);

      // Nếu độ sâu của targetItem đã đạt cấp 10, không cho phép kéo thả vào
      if (targetFlatItem && targetFlatItem.depth >= 9) {
        return;
      }

      // Hàm tìm node theo id trong cây
        const findNodeById = (nodes, itemId, visited = new Set()) => {
            for (const node of nodes) {
                if (visited.has(node.idNode)) continue;
                visited.add(node.idNode);

                if (node.idNode === itemId) return node;
                if (node.children) {
                    const found = findNodeById(node.children, itemId, visited);
                    if (found) return found;
                }
            }
            return null;
        };

        const draggedNode = findNodeById(this.treeData, this.draggedItem.idNode);
        if (!draggedNode) return;

        const targetNode = findNodeById(this.treeData, targetItem.idNode);
        if (!targetNode) return;

        // **Chặn kéo con vào chính cha nó**
        if (draggedNode.parent_id === targetNode.idNode) {
            return;
        }

        let parentNode = findNodeById(this.treeData, draggedNode.parent_id);
        let parentChildren = parentNode ? parentNode.children : this.treeData;

        // **Xác định vị trí cũ của draggedNode**
        let oldIndex = parentChildren.findIndex(node => node.idNode === draggedNode.idNode);

        // **Tách toàn bộ con của draggedNode và chèn vào đúng vị trí cũ**
        if (draggedNode.children.length > 0) {
            parentChildren.splice(oldIndex, 1, ...draggedNode.children);
            draggedNode.children.forEach(child => {
                child.parent_id = parentNode ? parentNode.idNode : null;
            });
        } else {
            parentChildren.splice(oldIndex, 1);
        }

        // **Di chuyển draggedNode vào targetNode**
        draggedNode.children = [];
        if (!targetNode.children) targetNode.children = [];
        targetNode.children.push(draggedNode);
        draggedNode.parent_id = targetNode.idNode;

        // **Cập nhật lại giao diện**
        this.updateFlatAllTreeview();
        this.draggedItem = null;
        this.updateDataToParent();
    },

    buildMap(data, map = {}) {
      for (let item of data) {
        map[item.idNode] = item;
        if (item.children && item.children.length > 0) {
          this.buildMap(item.children, map);
        }
      }
      return map;
    },
    findAndPushOptimized(data, itemFind, newItem) {
      const map = this.buildMap(data);

      if (map[itemFind.idNode]) {
        map[itemFind.idNode].children.push(newItem);
      }

      return data;
    },
    handleAddSubItem(proccess, data) {
      let newItem = {
        idNode: Date.now(),
        name: '',
        children: [],
        isOpen: true,
        cut_off: false,
        quantity: 1,
      };
      if (data?.status === 2) {
        newItem = {
          ...newItem,
          idProduct: data.selectValue.id,
          name: data.selectValue.text,
          ref_cfp_product_id: data.selectValue.id,
        };
      }
      this.treeData = this.findAndPushOptimized(this.treeData, proccess, newItem);
      this.treeData = this.addParentId(this.treeData);
      // refresh data
      this.updateFlatAllTreeview();
      this.updateDataToParent(newItem);
    },
    handleGetParentItem(parent, getTreeIndexByDepth, depth, startDepth) {
      if (depth === startDepth + 1) {
        return parent;
      } else {
        return this.handleGetParentItem(
          parent.children[getTreeIndexByDepth[startDepth + 1]],
          getTreeIndexByDepth,
          depth,
          startDepth + 1,
        );
      }
    },
    removeAndMoveItemLarge(data, itemFind) {
      let stack = [...data];
      let parentMap = new Map();

      while (stack.length) {
        let node = stack.pop();
        if (node.children) {
          for (let child of node.children) {
            parentMap.set(child.idNode, node.children);
            stack.push(child);
          }
        }
      }

      const removeNode = (arr, targetId) => {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].idNode === targetId) {
            let children = arr[i].children || [];
            let parentArray = parentMap.get(targetId) || data;
            arr.splice(i, 1);
            parentArray.splice(i, 0, ...children);
            return true;
          } else if (arr[i].children) {
            if (removeNode(arr[i].children, targetId)) return true;
          }
        }
        return false;
      };

      removeNode(data, itemFind.idNode);
      return data;
    },
    handleDeleteProcessItem(process) {
      this.treeData = this.removeAndMoveItemLarge(this.treeData, process);
      this.updateFlatAllTreeview();
      this.updateDataToParent();
      this.$forceUpdate();
    },
    handleApprovedProductPopup(product) {
      this.$emit('handleApprovedProductPopup', product);
    },
    updateDataToParent(newItem = {}) {
      this.$emit('updateChildData', this.boundaryIndex, this.treeData, newItem);
    },
    handleFocusInput() {
      this.isDraggable = true;
      this.$emit('focusInput');
    },
    handleBlurInput() {
      this.isDraggable = false;
      this.$emit('blurInput');
    },
  },
  mounted() {
    this.generateTree();
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  height: 500px;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $bgThumbScrollbar;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    cursor: auto;
  }
}
</style>
