<template>
  <div>
    <action-table-boundary
      @handleClickBtnBoundary="handleClickBtnBoundary"
      :disableBoundaryInput="disableBoundaryInput"
      :boundariesList="boundaries"
      :isExportProcess="isExportProcess"
    ></action-table-boundary>
    <draggable
      v-bind="dragOptions"
      :list="boundaries"
      class="boundary-wrapper"
      id="boundary-wrapper"
      :class="getModeRender"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <div
        class="boundary-item"
        v-for="(boundaryItem, boundaryIndex) in boundaries"
        :key="boundaryIndex"
        :class="isDisableDragDrop"
        :style="getStyleBoundary(boundaryItem)"
      >
        <div
          class="boundary-head"
          @mouseover="enableDrag"
          @mouseleave="disableDrag"
          @drop="onDropToBoundary($event, boundaryIndex)"
          @dragover.prevent
        >
          <div class="boundary-head_left">
            <svg
              v-if="!isBoundaryForEmission"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.5 7H11.5V9H9.5V7Z" fill="#99A6A9" />
              <path d="M12.5 7H14.5V9H12.5V7Z" fill="#99A6A9" />
              <path d="M9.5 11H11.5V13H9.5V11Z" fill="#99A6A9" />
              <path d="M12.5 11H14.5V13H12.5V11Z" fill="#99A6A9" />
              <path d="M9.5 15H11.5V17H9.5V15Z" fill="#99A6A9" />
              <path d="M12.5 15H14.5V17H12.5V15Z" fill="#99A6A9" />
            </svg>
            <svg
              @click="handleUpdatePosition(boundaryIndex)"
              :class="boundaryItem?.isOpen ? 'icon-open' : 'icon-close'"
              class="boundary-icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 11.5L13 4.5H3L8 11.5Z" fill="#6B838F" stroke="#6B838F" stroke-linejoin="round" />
            </svg>

            <div class="boundary-head-infor" v-if="!isBoundaryForEmission && !disableBoundaryInput">
              <input-field-small
                v-model="boundaryItem.name"
                :inputValue="boundaryItem.name"
                class="boundary-input"
                :isReadOnly="disableBoundaryInput"
                @focus="handleFocusInput('boundary')"
                @blur="handleBlurInput('boundary')"
                :label="$t('new_cfp.placeholder_boundary_process')"
              />
            </div>
            <input-disable
              v-if="(!isBoundaryForEmission && disableBoundaryInput) || isBoundaryForEmission"
              class="item-name"
              :text="boundaryItem.name"
              :isSmallInput="true"
            />
          </div>

          <div class="boundary-head_actions" v-if="!isBoundaryForEmission && !disableBoundaryInput">
            <button-custome
              icon="addregistericon.svg"
              iconHover="addregistericon_active.svg"
              iconDisable="addregistericon_disabled.svg"
              typeIcon="icons"
              :isSmallButton="true"
              :buttonText="$t('new_cfp.button_add_sub_item')"
              :isDisable="disableBoundaryInput"
              @action="handleOpenAddLowerLevelPopup(boundaryIndex)"
              class="boundary-btn"
            >
            </button-custome>
            <button-custome
              icon="products/process-delete.svg"
              iconHover="products/process-delete-hover.svg"
              iconDisable="products/process-delete-disable.svg"
              typeIcon="image"
              :isSmallButton="true"
              :buttonText="deleteBtn"
              :isDisable="disableBoundaryInput"
              class="boundary-btn"
              @action="handleDeleteBoundaryItem(boundaryIndex)"
            >
            </button-custome>
          </div>
          <!-- case emission detail -->
          <div class="boundary-head_actions" v-if="isBoundaryForEmission">
            <emission-information
              :isShowEditEmission="false"
              :process="boundaryItem"
              :selectedMode="selectedMode"
              :workflowData="getCustomWorkflowData"
            />
          </div>
        </div>
        <transition name="slide-down">
          <div class="wrapper-parent transition-content" v-if="boundaryItem?.isOpen" ref="treeContainer">
            <tree-scroller
              :arrayProcessInBoundary="boundaryItem.processes"
              :key="`boundary-${boundaryIndex}-${boundaryItem.idNode}`"
              :disableBoundaryInput="disableBoundaryInput"
              :isShowCutOff="true"
              :boundaryIndex="boundaryIndex"
              :processIndex="processIndex"
              :isAllowAddLayer="true"
              :selectedMode="selectedMode"
              class="tree-view"
              :contentHeight="treeContentHeight"
              :isBoundaryForEmission="isBoundaryForEmission"
              :workflowData="getCustomWorkflowData"
              :approvedProductList="approvedProductList"
              @updateChildData="handleUpdateChildData"
              @handleApprovedProductPopup="handleApprovedProductPopup"
              @deleteProcessItem="handleDeleteProcessItem"
              @focusInput="handleFocusInput"
              @blurInput="handleBlurInput"
            ></tree-scroller>
          </div>
        </transition>
      </div>
    </draggable>
    <action-table-boundary
      @handleClickBtnBoundary="handleClickBtnBoundary"
      :disableBoundaryInput="disableBoundaryInput"
      :boundariesList="boundaries"
      :isExportProcess="isExportProcess"
    ></action-table-boundary>
    <import-csv-popup
      v-show="openDialogImportCsvPopup"
      :boundariesList="boundariesList"
      v-model="openDialogImportCsvPopup"
      @handleDataToBoundary="handleDataToBoundary"
    ></import-csv-popup>

    <question-popup
      v-if="questionDeleteBoundaryPopup"
      :confirm="true"
      :confirmText="$t('popup.button_delete')"
      :dialog="questionDeleteBoundaryPopup"
      :message="questionDeleteBoundaryMessage"
      :isSaveButtonDelete="true"
      @close="questionDeleteBoundaryPopup = false"
      @submit="deleteBoundaryItem"
    />
    <add-a-lower-level-popup
      v-if="dialogAddALowerLevelPopup.openDialog"
      :dialog="dialogAddALowerLevelPopup.openDialog"
      :data="dialogAddALowerLevelPopup.dataBoundaryIndex"
      :approvedProductList="approvedProductList"
      @close="dialogAddALowerLevelPopup.openDialog = false"
      @submit="onHandleSelectData"
    />
    <export-process-boundary-popup
      v-if="dialogExportProcessPopup"
      :dialog="dialogExportProcessPopup"
      :boundariesList="boundariesList"
      @close="dialogExportProcessPopup = false"
      @submit="onExportProcessBoundaryHandler"
    />
  </div>
</template>
<script>
// UI import
import draggable from 'vuedraggable';
import TreeScroller from '@/components/products/TreeScroller';

import QuestionPopup from '@/components/dialogs/question-popup';
import InputDisable from '@/components/products/table/InputDisable';
import ButtonCustome from '@/components/commonApp/ButtonIconCustom';
import ImportCsvPopup from '@/components/products/import-csv/index.vue';
import InputFieldSmall from '@/components/products/input/InputFieldSmall';
import AddALowerLevelPopup from '@/components/dialogs/add-a-lower-level-popup';
import ExportProcessBoundaryPopup from '@/components/dialogs/export-process-boundary-popup';
import ActionTableBoundary from '@/components/products/common/action-boundary.vue';
import EmissionInformation from '@/components/products/common/emission-information.vue';

import _ from 'lodash';
import { STATUS_FIELD } from '@/constants/status';
import { DEFAULT_BOUNDARY, MAX_LAYER } from '@/constants/products/define-data';
import { findItemInTreeByIdNode, cloneItem, findParentByIdNode, handleProcessDataInTree } from '@/utils/cfpEmissions';

export default {
  components: {
    draggable,
    InputFieldSmall,
    InputDisable,
    ButtonCustome,
    QuestionPopup,
    ActionTableBoundary,
    ImportCsvPopup,
    AddALowerLevelPopup,
    EmissionInformation,
    ExportProcessBoundaryPopup,
    TreeScroller
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    isBoundaryForEmission: {
      type: Boolean,
      default: false,
    },
    isDetailProduct: {
      type: Boolean,
      default: false,
    },
    workflowData: {
      type: Object,
      default: () => {},
    },
    isDisableInputProductData: {
      type: Boolean,
      default: false,
    },
    isUpdateBoundary: {
      type: Boolean,
      default: false,
    },
    selectedMode: {
      type: Number,
      default: 1,
    },
    approvedProductList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      boundaries: _.cloneDeep(DEFAULT_BOUNDARY),
      dragOptions: {
        animation: 200,
        disabled: true,
        ghostClass: 'ghost',
        scrollSensitivity: 100,
        swapThreshold: 1,
        scroll: true,
        forceAutoScrollFallback: true,
        forceFallback: true,
        bubbleScroll: true,
      },
      dragProcessOptions: {
        animation: 200,
        disabled: true,
        ghostClass: 'ghost',
        scrollSensitivity: 100,
        swapThreshold: 1,
        scroll: true,
        forceAutoScrollFallback: true,
        forceFallback: true,
        bubbleScroll: true,
      },
      processIndex: null,
      boundaryIndex: null,
      questionDeleteBoundaryPopup: false,
      questionDeleteBoundaryMessage: '',
      reRenderBtnKey: 0,
      openDialogImportCsvPopup: false,
      dialogAddALowerLevelPopup: {
        openDialog: false,
        dataBoundaryIndex: null,
      },
      dialogExportProcessPopup: false,
    };
  },
  mounted() {
    if (!this.isUpdateBoundary) {
      this.$emit('updateBoundaries', this.boundaries);
    }
  },
  computed: {
    deleteBtn() {
      return this.selectedMode === 2 ? this.$t('product_boundary.button_delete') : '';
    },
    addBoundaryBtn() {
      return this.$t('product_boundary.button_add_boundary');
    },
    addProcessBtn() {
      return this.$t('product_boundary.button_add_process');
    },
    questionMessage() {
      return this.$t('cfp_emissions_product.message_delete_process');
    },
    disabledBoundary() {
      return (
        [STATUS_FIELD.STATUS_SUBMIT, STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(
          this.workflowData?.status,
        ) || this.isBoundaryForEmission
      );
    },
    disableBoundaryInput() {
      return this.isDisableInputProductData;
    },
    isExportProcess() {
      return this.isBoundaryForEmission;
    },
    isDisableDragDrop() {
      return this.disabledBoundary ? 'disabled' : '';
    },
    boundariesList() {
      return this.boundaries.map((item, index) => ({ name: item.name, key: index.toString(), id: item.id?.toString() }));
    },
    getModeRender() {
      let classList = this.selectedMode === 1 ? 'card-list' : 'normal-list';
      if (this.isBoundaryForEmission) {
        classList += ' emission-type';
      }
      return classList;
    },
    getCustomWorkflowData() {
      return {
        id: this.workflowData.id,
        product_unit: this.workflowData.product_unit,
      };
    },
    treeContentHeight() {
      return this.selectedMode === 1 ? 500 : 450;
    },
  },
  methods: {
    getStyleBoundary(boundary) {
      const sizeLength = this.getObjectDepth(boundary, 0, new Set(), 'processes').size;
      const defaultWidth = 428;
      if (sizeLength <= 5) return `min-width: ${defaultWidth}px;`;

      const numberItemNeedToAddWidth = sizeLength - 5;
      const lengthNeedToAdd = numberItemNeedToAddWidth * 16;
      return `min-width: ${lengthNeedToAdd + defaultWidth}px;`;
    },
    getObjectDepth(node, currentDepth = 0, layersWithChildren = new Set(), childKey = 'children') {
      if (node[childKey] && node[childKey].length > 0) {
        layersWithChildren.add(currentDepth);

        for (const child of node[childKey]) {
          this.getObjectDepth(child, currentDepth + 1, layersWithChildren);
        }
      }

      return layersWithChildren;
    },
    isAllowAddMoreLayer(process) {
      return this.getObjectDepth(process).size < MAX_LAYER - 2;
    },
    handleToggleProcess(boundaryIndex, processIndex) {
      this.boundaries[boundaryIndex].processes[processIndex].isOpen =
        !this.boundaries[boundaryIndex].processes[processIndex].isOpen;
    },
    handleUpdatePosition(boundaryIndex) {
      this.boundaries[boundaryIndex].isOpen = !this.boundaries[boundaryIndex]?.isOpen || false;
      this.$emit('updateBoundaries', this.boundaries);
    },
    handleDeleteProcessItem(boundaryIndex, processIndex) {
      //set index off boundary, process & message confirm delete process by name
      this.dragProcessOptions.disabled = true;

      this.boundaryIndex = boundaryIndex;
      this.processIndex = processIndex;
      const { boundarySelected, processSelected } = this.handleGetProcessAndBoundarySelected();
      if (!boundarySelected || !processSelected) return;
      const cloneProcessItem = cloneItem(boundarySelected['processes']);
      boundarySelected['processes'] = handleProcessDataInTree({
        childItems: cloneProcessItem,
        index: processIndex,
        childrenItemOfProcessDeleted: processSelected?.children,
        arrayToSetData: cloneProcessItem,
      });
      this.$emit('updateBoundaries', this.boundaries);
    },
    handleDeleteBoundaryItem(boundaryIndex) {
      this.boundaryIndex = boundaryIndex;
      this.questionDeleteBoundaryPopup = true;
      this.questionDeleteBoundaryMessage = this.$t('cfp_emissions_product.message_delete_boundary');
    },
    handleGetProcessAndBoundarySelected() {
      const boundarySelected = this.boundaries[this.boundaryIndex];
      const processSelected = boundarySelected['processes'][this.processIndex];
      return { boundarySelected, processSelected };
    },
    addBoundaryItem() {
      this.boundaries.push({
        name: '',
        processes: [{ name: '', cut_off: false, children: [], idNode: Date.now(), isOpen: true, quantity: 1 }],
        isOpen: true,
        quantity: 1,
        children: [],
      });
      const element = document.getElementById('boundary-wrapper');
      setTimeout(() => {
        element.scrollLeft = element.scrollWidth - element.clientWidth;
      }, 100);
      this.$emit('updateBoundaries', this.boundaries);
    },
    addProcessItem(data) {
      let newItem = {
        name: '',
        cut_off: false,
        children: [],
        idNode: Date.now(),
        isOpen: true,
        quantity: 1,
      };
      if (data?.status === 2) {
        newItem = {
          ...newItem,
          idProduct: data.selectValue.id,
          name: data.selectValue.text,
          ref_cfp_product_id: data.selectValue.id,
        };
      }

      this.boundaries[data.indexBoundary]['processes'].push(newItem);
      // re-render, tránh trùng idNode của boundrary với idNode của process để khi tìm by idNode
      this.boundaries[data.indexBoundary]['idNode'] = Date.now() + Math.floor(Math.random() * 10000) - 5000;;
      this.$emit('updateBoundaries', this.boundaries);
      this.dialogAddALowerLevelPopup.openDialog = false;

      this.$nextTick(() => {
        const treeContainerElement = document.getElementById(newItem.idNode);
        if (treeContainerElement) {
          treeContainerElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    },

    handleOpenAddLowerLevelPopup(index) {
      this.dialogAddALowerLevelPopup.openDialog = true;
      this.dialogAddALowerLevelPopup.dataBoundaryIndex = index;
    },
    onHandleSelectData(selectedValue) {
      this.addProcessItem(selectedValue);
    },
    handleMoveSubProcessToProcess(payloads) {
      const { boundaryIndex, processIndex, draggedItem, indexInTree, cloneChildOfDraggedItem } = payloads;
      const boundaryItem = this.boundaries[boundaryIndex];
      let processList = boundaryItem.processes;

      processList[processIndex]?.children?.splice(indexInTree, 1);
      boundaryItem.processes = [
        ...cloneItem([...boundaryItem.processes.slice(0, processIndex + 1)]),
        { ...draggedItem, children: [] },
        ...cloneItem([...boundaryItem.processes.slice(processIndex + 1, boundaryItem.processes.length)]),
      ];

      if (cloneChildOfDraggedItem.length) {
        // if draggedItem has child => move child of draggedItem to next lever
        processList[processIndex]?.children?.push(...cloneChildOfDraggedItem);
      }
      this.$emit('updateBoundaries', this.boundaries);
    },
    handleMoveProcessToSubProcess(payloads) {
      const { draggedItem, boundaryIndex, targetItemId } = payloads;
      const boundaryItem = this.boundaries[boundaryIndex];
      const indexOfDragInBoundary = boundaryItem.processes.findIndex((item) => item.idNode === draggedItem.idNode);
      const childOfDraggedItem = cloneItem(boundaryItem.processes[indexOfDragInBoundary].children);

      boundaryItem.processes.splice(indexOfDragInBoundary, 1);
      boundaryItem.processes.splice(indexOfDragInBoundary, 0, ...childOfDraggedItem);

      // push process drag to target children
      const targetItem = findItemInTreeByIdNode(boundaryItem, targetItemId, 'processes');
      targetItem.children.push({ ...draggedItem, children: [] });
      this.$emit('updateBoundaries', this.boundaries);
    },
    handleMoveProcessToAnotherProcessNode(payloads) {
      const { boundaryIndex, targetItemId, draggedItemId } = payloads;
      // define item to handle
      const boundaryItem = this.boundaries[boundaryIndex]; // target item is boundaryItem
      const draggedItem = findItemInTreeByIdNode(boundaryItem, draggedItemId, 'processes');
      const targetItem = findItemInTreeByIdNode(boundaryItem, targetItemId, 'processes');
      const cloneChildOfTargetItem = cloneItem(targetItem?.children);
      const cloneChildOfDraggedItem = cloneItem(draggedItem?.children);
      const indexOfProcessDrag = boundaryItem.processes?.findIndex((item) => item.idNode === draggedItemId);
      const parentOfDragItem = findParentByIdNode(boundaryItem, draggedItemId, null, 'processes');

      // push draggedItem to draggedItem => move cloneChildOfTargetItem to child of draggedItem
      targetItem.children = [];
      targetItem.children.push({ ...draggedItem, children: cloneChildOfTargetItem });

      if (parentOfDragItem?.children) {
        // case drag sub process
        parentOfDragItem?.children?.splice(indexOfProcessDrag, 1);
        if (cloneChildOfDraggedItem.length) {
          // if draggedItem has child => move child of draggedItem to next lever
          parentOfDragItem.children.splice(indexOfProcessDrag, 0, ...cloneChildOfDraggedItem);
        }
      }

      if (parentOfDragItem?.processes) {
        // case drag process
        parentOfDragItem?.processes?.splice(indexOfProcessDrag, 1);
        if (cloneChildOfDraggedItem.length) {
          // if draggedItem has child => move child of draggedItem to next lever
          parentOfDragItem.processes.splice(indexOfProcessDrag, 0, ...cloneChildOfDraggedItem);
        }
      }
      this.$emit('updateBoundaries', this.boundaries);
    },
    onDropToBoundary(event, boundaryIndex) {
      const jsonData = JSON.parse(event.dataTransfer.getData('application/json'));
      // only drop in same boundaryIndex
      if (boundaryIndex !== jsonData.boundaryIndex) return;

      // define item to handle
      const boundaryItem = this.boundaries[boundaryIndex]; // target item is boundaryItem
      const draggedItem = jsonData.draggedItem;
      const cloneChildOfDraggedItem = cloneItem(draggedItem?.children);
      const parentOfDragItem = findParentByIdNode(boundaryItem, draggedItem.idNode, null, 'processes');
      const indexOfDragInBoundary = boundaryItem.processes.findIndex((item) => item.idNode === draggedItem.idNode);
      // if boundary has draggedItemId id => stop drop
      if (indexOfDragInBoundary >= 0) return;

      boundaryItem.processes.push({ ...draggedItem, children: [], parent_id: null }); //
      const index = parentOfDragItem.children.findIndex(item => item.idNode === draggedItem.idNode);

      if (index !== -1) {
        parentOfDragItem.children.splice(index, 1, ...cloneChildOfDraggedItem);
      }
      
      this.$emit('updateBoundaries', this.boundaries);
      this.boundaries[boundaryIndex].idNode = Date.now();
    },
    deleteBoundaryItem() {
      const { boundarySelected } = this.handleGetProcessAndBoundarySelected();
      if (!boundarySelected) return;

      this.boundaries.splice(this.boundaryIndex, 1);
      this.questionDeleteBoundaryPopup = false;
      this.$emit('updateBoundaries', this.boundaries);
    },
    updateCutoffProcessItem(boundaryIndex, processIndex) {
      this.dragProcessOptions.disabled = true;
      this.boundaryIndex = boundaryIndex;
      this.processIndex = processIndex;
      const { boundarySelected, processSelected } = this.handleGetProcessAndBoundarySelected();
      if (!boundarySelected || !processSelected) return;

      this.$emit('updateBoundaries', this.boundaries);
      // this.dragProcessOptions.disabled = false;
    },
    onDragStart(e) {
      e.item.style.visibility = 'hidden';
      window.getSelection().removeAllRanges();
    },
    onDragEnd(e) {
      this.$emit('updateBoundaries', this.boundaries);
      e.item.style.visibility = 'visible';
    },
    handleFocusInput(type = 'process') {
      if (type === 'process') {
        this.dragProcessOptions.disabled = true;
      }
      this.disableDrag();
    },
    handleBlurInput(type) {
      if (type === 'boundary') {
        this.dragOptions.disabled = false;
      }
      this.dragProcessOptions.disabled = false;
      this.$emit('updateBoundaries', this.boundaries);
    },
    redirectToRegisterEmissions(process) {
      if (process?.id) {
        this.$router
          .push({ name: 'RegisterEmissions', params: { processId: process.id }, query: { ...this.$route.query } })
          .catch(() => {});
      }
    },
    hanleMouseover() {},
    handleMouseleave() {
      this.reRenderBtnKey++;
    },
    handleUpdateChildData(boundaryIndex, childData, newItem) {
      this.boundaries[boundaryIndex].processes = childData;
      this.$nextTick(() => {
        const treeContainerElement = document.getElementById(newItem.idNode);
        if (treeContainerElement) {
          treeContainerElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
    enableDrag() {
      if (this.disableBoundaryInput) {
        this.dragOptions.disabled = true;
        return;
      }
      this.dragOptions.disabled = false;
    },
    disableDrag() {
      this.dragOptions.disabled = true;
    },
    handleClickBtnBoundary(value) {
      const handlers = {
        importCsvHandler: this.importCsvHandler,
        addBoundaryItem: this.addBoundaryItem,
        exportProcessHandler: this.exportProcessHandler
      };

      if (handlers[value]) {
        handlers[value]();
      }
    },
    importCsvHandler() {
      this.openDialogImportCsvPopup = true;
    },
    exportProcessHandler() {
      setTimeout(() => {
        this.dialogExportProcessPopup = true;
      }, 10)
    },
    onExportProcessBoundaryHandler(payload) {
      this.$emit('export', Number(payload.id));
    },
    handleDataToBoundary(dataArray, indexBoundary) {
      this.openDialogImportCsvPopup = false;
      // Has data on table & boundary is selected
      if (dataArray) {
        this.boundaries[indexBoundary].processes = dataArray;
        this.boundaries[indexBoundary].idNode = Date.now();
      }
    },
    handleApprovedProductPopup(product) {
      this.$emit('handleApprovedProductPopup', product);
    },
  },
  watch: {
    dataList: {
      handler() {
        if (!this.isDetailProduct && !this.isUpdateBoundary) return;
        this.boundaries = [...this.dataList];
      },
      deep: true,
      immediate: true,
    },
    workflowData: {
      handler() {
        // this.dragOptions.disabled = this.disabledBoundary;
        this.dragProcessOptions.disabled = true;
      },
      deep: true,
      immediate: true,
    },
    isDetailProduct: {
      handler(value) {
        if (!value && !this.isUpdateBoundary) {
          this.boundaries = _.cloneDeep(DEFAULT_BOUNDARY);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.item-name {
  min-width: 200px;
}
.boundary-btn {
  padding: 4px 8px 6px 8px;
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease-out;
}

.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-10%);
  opacity: 0;
  height: 0;
}

.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0);
  opacity: 1;
  height: max-content;
}

.boundary-wrapper {
  display: flex;
  flex-flow: row;
  gap: 16px;
  padding: 16px;
  max-width: 100%;
  background: $monoOffWhite;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 0;
  &.emission-type {
    padding-top: 16px;
  }
  @include desktop {
    padding-top: 16px;
  }
  &::-webkit-scrollbar {
    height: 8px !important;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 4px;
    z-index: 1000;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $bgMid;
  }

  &::-webkit-scrollbar-corner {
    background: $bgMid;
  }

  &.normal-list {
    flex-direction: column;
    background: #eff6f6;
    .boundary-item {
      width: 100%;
      min-width: 1082px !important;
      background: $bgCusLight;
      padding: 0;
      cursor: move;
      .boundary-input {
        width: 200px;
        height: 28px;
        border: unset;
      }
      .wrapper-parent {
        // max-height: 450px;
        // overflow-y: auto;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $bgThumbScrollbar;
          border-radius: 10px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
        }
        .tree-view {
          padding: 0;
        }
        .process-wrapper {
          display: flex;
          flex-flow: column;
          gap: 8px;
          margin-bottom: 16px;
          .process-infor {
            display: flex;
            flex-direction: column;
            .process-item {
              display: flex;
              flex-flow: column;
              flex-direction: row;
              justify-content: space-between;
              padding: 8px 8px 8px 8px;
              background: $bgExtraLight;
              border-bottom: 1px solid rgba(42, 42, 48, 0.1);
              .process-cutoff-label {
                color: $monoBlack;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.42px;
                display: flex;
                align-items: center;
                gap: 6px;
                // margin: 8px 0 2px;
                .label {
                  cursor: pointer;
                }
                .checkbox-container {
                  display: flex;
                  position: relative;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  width: 16px;
                  height: 16px;
                }
                input[type='checkbox'] {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  opacity: 0;
                  cursor: pointer;
                  &:checked ~ .checkmark {
                    background-color: $blueMid;
                    &::after {
                      display: block;
                    }
                  }
                  &:hover ~ .checkmark {
                    border: 1px solid rgba(0, 0, 0, 0.4);
                  }
                  &:checked:hover ~ .checkmark {
                    border: 1px solid rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 4px 10px rgba(0, 0, 0, 0.15) inset;
                  }
                  &.disabled {
                    stroke-width: 0.5px;
                    stroke: rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
                    background: $grayDark !important;
                    &:hover {
                      cursor: unset;
                    }
                  }
                  &.disabled ~ .checkmark {
                    opacity: 0.8;
                    background: $grayDark;
                  }
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $monoWhite;
                  border-radius: 4px;
                  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
                  border: 1px solid rgba(0, 0, 0, 0.25);
                  &::after {
                    content: '';
                    position: absolute;
                    left: 4px;
                    top: 0px;
                    width: 6px;
                    height: 11px;
                    border: solid $monoWhite;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    display: none;
                  }
                }
                &.disabled {
                  .label {
                    cursor: unset;
                  }
                }
              }
              .process-delete {
                width: max-content;
                min-width: 61px;
                height: 28px !important;
                padding: 4px 8px 6px !important;
                margin: 0 auto;
                ::v-deep {
                  .v-btn__content {
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.33px;
                    color: $monoBlack;
                    .icon {
                      width: 16px;
                      height: 16px;
                      margin-right: 6px !important;
                    }
                  }
                }
              }
              .process-input {
                max-width: 200px;
              }
              .process-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
              }
              &.sub-item {
                padding-left: 8px;
              }
              &-input {
                display: flex;
                align-items: center;
                padding-left: 4px;
                &--right {
                  display: flex;
                  min-width: 200px;
                  align-items: center;
                  gap: 8px;
                }
              }
            }

            .process-sub-item {
              display: flex;
              flex-direction: column;
            }
          }

          &::-webkit-scrollbar {
            width: 16px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $bgThumbScrollbar;
            border-radius: 10px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
          }
          &.disabled {
            cursor: auto;
          }
        }
      }
      .boundary-button {
        display: flex;
        flex-flow: row;
        gap: 8px;
        text-transform: capitalize;
        .common-btn {
          width: 105px !important;
          ::v-deep {
            .v-btn__content {
              text-transform: capitalize !important;
            }
          }
        }
      }
      .item-perproduct {
        margin: 10px 0 16px 0;
      }
      .button-edit {
        width: 61px;
        height: 28px;
        margin: 0 auto;
        border-radius: 4px;
        border: 1px solid $monoBlackDark;
        background: $monoOffWhite;
        font-weight: 700;
        padding: 4px 8px 6px 8px;
        font-size: 11px;
        .icon {
          margin-right: 6px;
        }
      }

      .boundary-head {
        display: flex;
        padding: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background: $bgMid;
        .boundary-input {
          max-width: 200px;
        }
        &_actions {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
        }
        &_left {
          display: flex;
          padding-left: 4px;
          align-items: center;
          gap: 8px;
          .boundary-icon {
            cursor: pointer;
            transition: all 0.3s ease-out;
          }
          .icon-close {
            transform: rotate(-90deg);
          }
          .boundary-head-infor {
            display: flex;
            align-items: center;
            gap: 16px;
            &__quantity {
              display: flex;
              align-items: center;
              gap: 8px;
              &.label {
                font-size: 11px;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.33px;
                min-width: 25px;
              }
              .quantity-input {
                width: 60px;
                border: unset;
              }
            }
          }
        }
      }
      &.disabled {
        cursor: auto;
        .boundary-head {
          &_actions {
            width: calc(100% - 236px);
          }
          &_left {
            max-width: 228px;
          }
        }
      }
      @include desktop {
        min-width: 250px !important;
      }
    }
  }

  &.card-list {
    flex-direction: row;
    background: $bgExtraLight;
    .boundary-item {
      width: 100%;
      min-width: 428px;
      max-width: 428px;
      // background: $bgCusLight;
      padding: 0;
      cursor: move;
      &.disabled {
        cursor: auto;
      }
      .boundary-input {
        width: 200px;
        height: 28px;
        border: unset;
      }
      .wrapper-parent {
        // max-height: 500px;
        // overflow-y: auto;
        background: $bgCusLight;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $bgThumbScrollbar;
          border-radius: 10px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          cursor: auto;
        }
        .tree-view {
          padding: 8px 8px 0 8px;
        }
        .process-wrapper {
          display: flex;
          flex-flow: column;
          gap: 8px;
          margin-bottom: 16px;
          .process-infor {
            display: flex;
            flex-direction: column;
            .process-item {
              display: flex;
              flex-flow: column;
              flex-direction: row;
              justify-content: space-between;
              padding: 8px 8px 8px 8px;
              background: $bgExtraLight;
              border-bottom: 1px solid rgba(42, 42, 48, 0.1);
              .process-cutoff-label {
                color: $monoBlack;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.42px;
                display: flex;
                align-items: center;
                gap: 6px;
                // margin: 8px 0 2px;
                .label {
                  cursor: pointer;
                }
                .checkbox-container {
                  display: flex;
                  position: relative;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  width: 16px;
                  height: 16px;
                }
                input[type='checkbox'] {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  opacity: 0;
                  cursor: pointer;
                  &:checked ~ .checkmark {
                    background-color: $blueMid;
                    &::after {
                      display: block;
                    }
                  }
                  &:hover ~ .checkmark {
                    border: 1px solid rgba(0, 0, 0, 0.4);
                  }
                  &:checked:hover ~ .checkmark {
                    border: 1px solid rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 4px 10px rgba(0, 0, 0, 0.15) inset;
                  }
                  &.disabled {
                    stroke-width: 0.5px;
                    stroke: rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
                    background: $grayDark !important;
                    &:hover {
                      cursor: unset;
                    }
                  }
                  &.disabled ~ .checkmark {
                    opacity: 0.8;
                    background: $grayDark;
                  }
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $monoWhite;
                  border-radius: 4px;
                  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08) inset;
                  border: 1px solid rgba(0, 0, 0, 0.25);
                  &::after {
                    content: '';
                    position: absolute;
                    left: 4px;
                    top: 0px;
                    width: 6px;
                    height: 11px;
                    border: solid $monoWhite;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    display: none;
                  }
                }
                &.disabled {
                  .label {
                    cursor: unset;
                  }
                }
              }
              .process-delete {
                width: max-content;
                min-width: 61px;
                height: 28px !important;
                padding: 4px 8px 6px !important;
                margin: 0 auto;
                ::v-deep {
                  .v-btn__content {
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.33px;
                    color: $monoBlack;
                    .icon {
                      width: 16px;
                      height: 16px;
                      margin-right: 6px !important;
                    }
                  }
                }
              }
              .process-input {
                max-width: 200px;
              }
              .process-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
              }
              &.sub-item {
                padding-left: 8px;
              }
              &-input {
                display: flex;
                align-items: center;
                padding-left: 4px;
                &--right {
                  display: flex;
                  min-width: 200px;
                  align-items: center;
                  gap: 8px;
                }
              }
            }

            .process-sub-item {
              display: flex;
              flex-direction: column;
            }
          }

          &::-webkit-scrollbar {
            width: 16px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $bgThumbScrollbar;
            border-radius: 10px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
          }
          &.disabled {
            cursor: auto;
          }
        }
      }
      .boundary-button {
        display: flex;
        flex-flow: row;
        gap: 8px;
        text-transform: capitalize;
        .common-btn {
          width: 105px !important;
          ::v-deep {
            .v-btn__content {
              text-transform: capitalize !important;
            }
          }
        }
      }
      .item-perproduct {
        margin: 10px 0 16px 0;
      }
      .button-edit {
        width: 61px;
        height: 28px;
        margin: 0 auto;
        border-radius: 4px;
        border: 1px solid $monoBlackDark;
        background: $monoOffWhite;
        font-weight: 700;
        padding: 4px 8px 6px 8px;
        font-size: 11px;
        .icon {
          margin-right: 6px;
        }
      }

      .boundary-head {
        display: flex;
        flex-direction: column;
        padding: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background: $bgMid;
        min-height: 80px;
        width: 100%;
        &_actions {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          gap: 8px;
          width: 100%;
        }
        &_left {
          display: flex;
          padding-left: 4px;
          align-items: center;
          gap: 8px;
          width: 100%;
          .boundary-icon {
            display: none;
          }
          .icon-close {
            transform: rotate(-90deg);
          }
          .boundary-head-infor {
            display: flex;
            align-items: center;
            gap: 16px;
            width: calc(100% - 32px);
            &__quantity {
              display: flex;
              align-items: center;
              gap: 8px;
              &.label {
                font-size: 11px;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.33px;
                min-width: 25px;
              }
              .quantity-input {
                width: 60px;
                border: unset;
              }
            }
          }
          .input-disabled {
            width: 100%;
          }
        }
      }
      &.disabled {
        .boundary-head {
          min-height: 70px;
        }
        .boundary-head_actions {
          .emission-information {
            padding: 0px 0px 0 6px;
          }
        }
      }
    }
  }
}
.boundary-add-wrapper {
  margin: 16px 0 80px;
  display: flex;
  justify-content: center;
  .boundary-add {
    width: 300px;
  }
}
.ghost {
  opacity: 0.5;
}
.process-icon {
  cursor: pointer;
}
</style>
