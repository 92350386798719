import { formatNumberBySetting } from '@/concerns/newRegisterData/wijmo.helper';
const NUMBER_KEY = ['quantity'];

const findItemInTreeByIdNode = (tree, idNode, keyOfChild = 'children') => {
  if (tree.idNode === idNode) {
    return tree;
  }

  // If the current node has children, search them
  if (tree[keyOfChild] && tree[keyOfChild].length > 0) {
    for (let child of tree[keyOfChild]) {
      const found = findItemInTreeByIdNode(child, idNode);
      if (found) {
        return found;
      }
    }
  }

  // If not found in this branch, return null
  return null;
};

const cloneItem = (item) => {
  if (!item) return item;
  return JSON.parse(JSON.stringify(item));
};

const findParentByIdNode = (tree, idNode, parent = null, keyOfChild = 'children') => {
  if (tree.idNode === idNode) {
    return parent;
  }

  if (tree[keyOfChild] && tree[keyOfChild].length > 0) {
    for (let child of tree[keyOfChild]) {
      const found = findParentByIdNode(child, idNode, tree);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

const extractPayload = (obj, keys, result = {}, isChild = false, position = null) => {
  for (const [key, value] of Object.entries(obj)) {
    if (keys.includes(key)) {
      const realValue = NUMBER_KEY.includes(key) ? formatNumberBySetting(value) : value;
      result[key] = realValue;
    }
    if (key === 'children' && Array.isArray(value)) {
      result[key] = value.map((child, index) => extractPayload(child, keys, {}, true, index));
    } else if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        result[key] = value.map((item) => extractPayload(item, keys, {}, false));
      } else {
        result[key] = extractPayload(value, keys, {}, false);
      }
      // Remove empty objects
      if (Object.keys(result[key]).length === 0) {
        delete result[key];
      }
    }
  }
  if (isChild && position !== null) {
    result.position = position + 1;
  }
  return result;
};

const getCustomBoundaryForState = (obj, initialIsOpen = true) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => getCustomBoundaryForState(item, initialIsOpen));
  }

  const result = { ...obj, isOpen: true, idNode: obj?.id || Date.now() };

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      result[key] = getCustomBoundaryForState(value, initialIsOpen);
    }
  }

  return result;
};

const validateTree = (tree) => {
  // Kiểm tra nếu tree là một mảng
  if (Array.isArray(tree)) {
    for (const node of tree) {
      // Gọi đệ quy cho từng node
      const result = validateTree(node);
      if (!result.isValidName || !result.isValidQuantity || !result.isMaxNumberLength) {
        return result; // Trả về kết quả nếu không hợp lệ
      }
    }
  } else if (typeof tree === 'object' && tree !== null) {
    // Kiểm tra nếu node có thuộc tính name
    let isValid = {
      isValidName: true,
      isValidQuantity: true,
      isMaxNumberLength: true,
    };
    if (!tree.name || typeof tree.name !== 'string' || tree.name.trim() === '') {
      isValid.isValidName = false;
    }
    // Kiểm tra nếu node có thuộc tính quantity
    if (!tree.quantity || typeof tree.quantity !== 'string' || tree.quantity.trim() === '') {
      isValid.isValidQuantity = false;
    }
    // Kiểm tra nếu node có thuộc tính quantity vượt quá số ký tự cho phép
    if (
      tree?.quantity &&
      typeof tree?.quantity === 'string' &&
      tree?.quantity.trim() !== '' &&
      tree?.quantity.length > 25
    ) {
      isValid.isMaxNumberLength = false;
    }

    if (!isValid.isValidName || !isValid.isValidQuantity || !isValid.isMaxNumberLength) {
      return isValid; // Trả về kết quả nếu không hợp lệ
    }
    // Kiểm tra nếu node có children và gọi đệ quy
    if (Array.isArray(tree.children)) {
      const result = validateTree(tree.children);
      if (!result.isValidName || !result.isValidQuantity || !isValid.isMaxNumberLength) {
        return result; // Trả về kết quả nếu không hợp lệ
      }
    }
  }

  return { isValidName: true, isValidQuantity: true, isMaxNumberLength: true }; // Nếu tất cả đều hợp lệ
};

const handleProcessDataInTree = (dataProps) => {
  let { childItems, index, childrenItemOfProcessDeleted, arrayToSetData } = dataProps;
  arrayToSetData.splice(index, 1); // remove item
  if (childrenItemOfProcessDeleted && childrenItemOfProcessDeleted.length) {
    childrenItemOfProcessDeleted.forEach((child) => {
      arrayToSetData.splice(index, 0, child); // set item to index
      index += 1;
    });
  }
  return childItems;
};

export {
  findItemInTreeByIdNode,
  cloneItem,
  findParentByIdNode,
  extractPayload,
  getCustomBoundaryForState,
  validateTree,
  handleProcessDataInTree,
};
