<template>
  <div class="emission-information" :class="selectedMode === 1 ? 'card-list' : 'normal-list'">
    <div class="emission-information__calculation">
      <div v-if="isProcessItem" class="emission-information__label" :class="isSubProcess && 'sub_process'">
        {{ getLabel }}
      </div>
      <div class="emission-information__label" :class="isSubProcess && 'sub_process'">{{ getToTalLabel }}</div>
    </div>
    <div class="emission-information_details">
      <div v-if="isCreatedBySupplier" class="emission-information__primary-data-label">
        <span>{{ $t('cfp_emissions_product.label_primary_data') }}</span>
      </div>
      <button-custom
        v-if="isShowRegisterEmissions"
        class="emission-information__btn"
        icon="products/edit-active.svg"
        iconHover="products/edit-hover.svg"
        :label="getLabelEditButton"
        @action="redirectToRegisterEmissions"
      />
    </div>
  </div>
</template>

<script>
import ButtonCustom from '@/components/commonApp/Button';
import { formatNumberByConditions, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
export default {
  name: 'EmissionInformation',
  components: { ButtonCustom },
  props: {
    emission: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    isSubProcess: {
      type: Boolean,
      default: false,
    },
    isShowEditEmission: {
      type: Boolean,
      default: true,
    },
    selectedMode: {
      type: Number,
      default: 1,
    },
    process: {
      type: Object,
      default: () => {},
    },
    workflowData: {
      type: Object,
      default: () => {},
    },
    isProcessItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getLabel() {
      if (this.process.cut_off) return;
      return `${this.$t('new_cfp.lable_emission_each_process')} ${this.processEmissions[0]}${this.processEmissions[1]}`;
    },
    getToTalLabel() {
      if (this.process.cut_off) return this.$t('cfp_emissions_product.title_cut_off');
      return `${this.$t('new_cfp.label_total_emission')} ${this.totalProcessEmissions[0]}${
        this.totalProcessEmissions[1]
      }`;
    },
    totalProcessEmissions() {
      const valueEmission = this.process?.emission_processes_actual || this.process?.emission_boundaries;
      let emissionsVal =
        valueEmission === null || valueEmission === undefined ? '---' : this.formatValueNumberFullData(valueEmission, true);
      return [emissionsVal, ` kg-CO2e`];
    },
    processEmissions() {
      const valueEmission = this.process?.emission_processes;
      let emissionsVal =
        valueEmission === null || valueEmission === undefined ? '---' : this.formatValueNumberFullData(valueEmission);
      return [emissionsVal, ` kg-CO2e`];
    },
    getLabelEditButton() {
      return this.selectedMode === 2 ? this.$t('cfp_emissions_product.button_edit_product_information') : '';
    },
    isShowRegisterEmissions() {
      return this.isShowEditEmission && !this.process?.ref_cfp_product_id;
    },
    isCreatedBySupplier() {
      return this.process?.created_by_supplier && this.isProcessItem;
    },
  },
  methods: {
    redirectToRegisterEmissions() {
      if (this.process?.id) {
        this.$router
          .push({ name: 'RegisterEmissions', params: { processId: this.process.id }, query: { ...this.$route.query } })
          .catch(() => {});
      }
    },
    formatValueNumberFullData(number) {
      if (!number) return '---';
      if (number === '0') return '0.00';
      return formatNumberByConditions(
        addThoundSandCommaWithTwoDigit(formatBigNumber(number, 25).replace(/[^0-9\.\-]+/g, ''), '', false, true),
        {
          isRealNumber: true,
          isGetMoreZeroInDecimalPart: true,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.emission-information {
  display: flex;
  width: 100%;
  padding: 0px 8px 8px 8px;
  justify-content: space-between;
  align-items: center;
  &__btn {
    ::v-deep {
      &.common-btn {
        height: 26px !important;
        width: 32px !important;
        min-width: unset;
        display: flex;
        padding: 4px 8px 6px 8px !important;
        justify-content: center;
        gap: 6px;
        border: 1px solid $dividerBlack;
        .v-btn__content {
          width: 16px !important;
          font-size: 11px;
          font-weight: 700;
          line-height: 18px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 6px;
          .icon {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
  &__calculation {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__label {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px; /* 163.636% */
    letter-spacing: 0.33px;
    color: $monoBlack;
    &.sub_process {
      font-weight: 500;
      color: $monoMid;
    }
  }
  &.normal-list {
    padding-bottom: 0;

    .emission-information__calculation {
      flex-direction: row;
      gap: 16px;
    }

    .emission-information__btn {
      ::v-deep {
        &.common-btn {
          width: 61px !important;
        }
      }
    }
  }
  .emission-information_details {
    display: flex;
    align-items: center;
    gap: 6px;

    .emission-information__primary-data-label {
      display: flex;
      align-items: center;
      font-size: 9px;
      font-weight: 700;
      line-height: 14px;
      height: 20px;
      color: $goldMid;
      padding: 3px 6px;
      border: 1px solid $goldMid;
      border-radius: 2px;
      background-color: $monoWhite;
    }
  }
}
</style>

