<template>
  <div class="product-approved" @click="handleClick">
    <svg
      class="product-approved-icon"
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4725_34255)">
        <path d="M4.5 7.5H1.5V10.5H4.5V7.5Z" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M14.5 17.5H11.5V20.5H14.5V17.5Z" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M14.5 12.5H11.5V15.5H14.5V12.5Z" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M14.5 7.5H11.5V10.5H14.5V7.5Z" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M4.5 9H11.5" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M8 14H11.5" stroke="#A9A04B" stroke-linejoin="round" />
        <path d="M11.5 19H8V9" stroke="#A9A04B" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4725_34255">
          <rect width="14" height="14" fill="white" transform="translate(1 7)" />
        </clipPath>
      </defs>
    </svg>
    <div class="product-approved-name">{{ getNameProduct }}</div>
  </div>
</template>

<script>
export default {
  name: 'ApprovalProductBtn',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getNameProduct() {
      return this.product?.name || '製品名がはいります';
    },
  },
  methods: {
    handleClick() {
      this.$emit('handleApprovedProductPopup', this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-approved {
  display: flex;
  height: 28px;
  padding: 7px 16px 9px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid $goldMid;
  background: $monoWhite;
  min-width: 124px;
  max-width: 200px;
  cursor: pointer;

  &-name {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
    color: $goldMid;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: initial;
  }
  &-icon {
    min-width: 17px;
  }
}
</style>
