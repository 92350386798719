<template>
  <div>
    <popup-fullscreen-header
      v-model="dialogVisible"
      :titlePopup="$t('new_cfp.title_popup_import_csv')"
      @clearDataPopUp="handleDeleteFile"
    >
      <template>
        <div class="form-main">
          <div class="padding-top-layout padding-right-layout popup-import-csv">
            <div class="listMenu-page list-menu">
              <div class="listMenus-btn">
                <div class="form-name">{{ $t('new_cfp.title_popup_import_csv') }}</div>
                <div style="width: 100%">
                  <div class="title-btn">
                    <span>{{ $t('new_cfp.label_popup_import_csv') }}</span>
                    <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
                  </div>
                  <div class="csv-file">
                    <div
                      class="csv-input"
                      v-on:dragenter="dragEnter"
                      v-on:dragover="dragOver"
                      v-on:dragleave="dragLeave"
                      v-on:drop="dropEvent"
                    >
                      <div class="import-csv">
                        <img :src="getIcon('upload')" alt="" class="upload-icon" />
                        <div class="csv-input-wrapper">
                          <input
                            type="file"
                            accept=".csv"
                            id="csv"
                            ref="csvInput"
                            class="d-none"
                            @change="handleAddFile"
                          />
                          <div class="csv-input-text">
                            {{ $t('list_menu.description_drag_and_drop_the_file_here') }}
                          </div>
                        </div>
                      </div>
                      <common-button
                        class="button-import"
                        :label="labelBtnSelectFile"
                        type="colored"
                        @action="$refs.csvInput.click()"
                        v-ripple="false"
                        id="import-csv"
                      />
                    </div>
                    <div v-if="csvFilename !== ''" class="csv-filename">
                      <span class="csv-filename-detail">{{ csvFilename }}</span>
                      <div class="remove-icon">
                        <img class="remove-icon-detail" :src="getIcon('remove')" alt="" @click="handleDeleteFile" />
                      </div>
                    </div>
                  </div>
                  <div class="csv-download">
                    <div class="des-template-csv">
                      <div class="csv-description">{{ $t('list_menu.label_maximum_upload_size') }}</div>
                      <p id="download-csv" class="csv-description-download mb-0 mr-0" @click="handleDownloadCsvSample">
                        {{ $t('list_menu.hyperlink_dowload_CSV') }}<span class="underline"></span>
                      </p>
                    </div>
                    <format-export-text :isImportData="true" @getUserEncoding="updateUserEncoding" />
                  </div>
                  <div class="list-menu-registration">
                    <div style="display: flex; gap: 8px; flex: 0 50%">
                      <common-button
                        class="submit-register-btn"
                        :label="$t('register_reduction.button_return')"
                        v-ripple="false"
                        @action="handleCancelImport"
                      />
                      <common-button
                        class="submit-register-btn"
                        :label="$t('new_cfp.button_upload_csv')"
                        type="colored"
                        v-ripple="false"
                        :disabled="validateForm()"
                        @action="uploadCsvFile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <notification-popup
          :dialog="dialogNotification"
          :message="messageNotify"
          @submit="(getDialog = false), (dialogNotification = false)"
        />

        <error-popup
          v-if="limit !== messageLimit"
          :dialog="dialogLimit"
          isErrorImg
          :message="messageLimit"
          @submit="dialogLimit = false"
        />
        <notification-popup
          v-if="limit === messageLimit"
          :dialog="dialogLimit"
          :message="messageLimit"
          @submit="dialogLimit = false"
        />
      </template>
    </popup-fullscreen-header>
    <preview-popup
      :boundariesList="boundariesList"
      @bindingDataToTree="bindingDataToTree"
      v-if="openDiaglogPreviewCsvPopup"
      v-model="openDiaglogPreviewCsvPopup"
      :csvFileContent="csvFileContent"
    ></preview-popup>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import FormatExportText from '@/components/csvFormat/export-text';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import PopupFullscreenHeader from '@/components/products/common/popup-fullscreen-header.vue';
import PreviewPopup from '@/components/products/import-csv/preview.vue';
import chardet from 'chardet';
import { getCsvCfpTemplate } from '@/api/importCsvCfp';
import { formatNumberRealNum } from '@/utils/convertNumber';
import { $_helper_isNumberType_bySetting, removeSpecialChars } from '@/concerns/newRegisterData/wijmo.helper';
export default {
  name: 'popupImport',
  components: { FormatExportText, CommonButton, NotificationPopup, ErrorPopup, PopupFullscreenHeader, PreviewPopup },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: '',
    },
    evidences: {
      type: Object,
    },
    descriptionScope: {
      type: Object,
      default() {
        return { data: '', note: '', value: '' };
      },
    },
    isShowDetail: {
      type: Boolean,
      default: true,
    },
    isProcessProduct: {
      type: Boolean,
      default: false,
    },
    boundariesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      csvFilename: '',
      csvFileData: '',
      messageLimit: '',
      dialogLimit: false,
      limitSize: false,
      userEncoding: null,
      dialogNotification: false,
      dialogConfirm: false,
      messageNotify: '',
      openDiaglogPreviewCsvPopup: false,
      csvFileContent: [],
    };
  },

  watch: {
    value: {
      handler() {
        // hidden scrolling default when popup fullscreen is opened
        setTimeout(() => {
          let htmlElement = document.getElementsByTagName('html')[0];
          htmlElement.style.overflow = this.value ? 'hidden' : 'auto';
        }, 50);
      },
      deep: true,
    },
  },

  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    limit() {
      return this.$t('list_menu.description_limit');
    },
    labelBtnSelectFile() {
      return this.$t('list_menu.button_select_files');
    },
  },
  methods: {
    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},
    handleAddFile(event) {
      if (event.target.files[0]) {
        this.handleValidateFile(event.target.files[0]);
      }
    },
    async dropEvent(event) {
      event.preventDefault();
      this.handleValidateFile(event.dataTransfer.files[0]);
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
      this.fileCsv = null;
    },
    updateUserEncoding(value) {
      this.userEncoding = value === 'Shift JIS' ? 'Shift_JIS' : 'UTF-8';
    },
    handleValidateFile(file) {
      if (file.type !== 'text/csv') {
        this.limitSize = true;
        this.messageLimit = this.$t('list_menu.description_limit');
        this.dialogLimit = true;
        return;
      } else if (file.size > 5242880) {
        this.limitSize = true;
        this.messageLimit = this.$t('list_menu.description_message_limit');
        this.dialogLimit = true;
        return;
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const resultArray = new Uint8Array(reader.result);
          let encoding = chardet.detect(resultArray);
          const utf8CompatibleEncodings = new Set(['ASCII', 'UTF-8', 'ISO-8859-1']);

          if (utf8CompatibleEncodings.has(encoding)) {
            encoding = 'UTF-8';
          }

          if (encoding !== this.userEncoding) {
            this.limitSize = true;
            this.messageLimit = this.$t('list_menu.description_message_notification');
            this.dialogLimit = true;
            return;
          }
          const decoder = new TextDecoder(encoding);
          this.csvFileData = decoder.decode(new Uint8Array(reader.result));
          this.csvFilename = file.name;
          this.fileCsv = file;
        };
        reader.readAsArrayBuffer(file);
        this.$refs.csvInput.value = '';
      }
    },
    async handleDownloadCsvSample() {
      try {
        const templateRes = await getCsvCfpTemplate();
        const fileName = templateRes.fileName;
        const url = window.URL.createObjectURL(
          new Blob([templateRes.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('error:', error);
      }
    },
    validateForm() {
      return !this.csvFileData;
    },
    uploadCsvFile() {
      // Read file content
      // Remove \r\n && '' lasted r
      const arrItems = this.csvFileData
        .split(/\r\n|\n/)
        .filter((line) => line.trim() !== '')
        .slice(1);

      if (arrItems.length && this.csvFileData.split(/\r\n|\n/)[0].split(',').length > 3) {
        this.openDiaglogPreviewCsvPopup = true;

        // Define column number need formatter
        const columnsToFormat = [4]; // 4 is column number quantity

        // Sử dụng regex để tách chính xác giá trị, kể cả khi có dấu phẩy bên trong chuỗi
        const data = arrItems.map((item, inv) => {
          const values = item.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g) || []; // Tách giá trị bằng regex
          const formattedValues = values.map((val) => val.replace(/^"|"$/g, '')); // Loại bỏ dấu ngoặc kép

          const obj = formattedValues.reduce((acc, val, index) => {
            const columnIndex = index + 1;
            acc[`header_${columnIndex}`] = columnsToFormat.includes(columnIndex)
              ? this.formatNumber(val) // Định dạng cột cần xử lý
              : val;
            return acc;
          }, {});

          obj['indexByRow'] = inv;

          return obj;
        });

        this.csvFileContent = data; // Lưu kết quả
        this.handleDeleteFile(); // clear file
      } else {
        this.messageLimit = this.$t('list_menu.description_message_notification');
        this.dialogLimit = true;
      }
    },
    formatNumber(input) {
      // If not a valid number, return input.
      if (!$_helper_isNumberType_bySetting(input)) return input;
      return formatNumberRealNum(removeSpecialChars(input));
    },
    bindingDataToTree(value, index) {
      this.openDiaglogPreviewCsvPopup = false;
      this.handleDeleteFile();
      this.$emit('handleDataToBoundary', value, index);
    },
    handleCancelImport() {
      this.handleDeleteFile();
      this.$emit('handleDataToBoundary');
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;

  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }

  .content-main {
    height: 100%;

    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }

    .description-download-file {
      color: $goldMid;
      cursor: pointer;

      font-size: 15px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.75px;
      text-decoration-line: underline;
      position: relative;
      width: max-content;
      text-align: left;

      .underline {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $goldMid;
        transition: width 0.3s ease;
        bottom: 2px;
      }

      &.disable {
        cursor: text;
        color: $monoBlack;
      }

      &:hover {
        text-decoration: underline;

        .underline {
          width: 100%;
        }
      }
    }

    .form-main {
      margin-top: 94px;

      .popup-import-csv {
        margin-top: 8px;
      }

      .listMenu-page {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 40px;
        flex: 1;

        .list-menu-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0px;

          width: 100%;

          // height: 40px;
          .return__header {
            display: none;
          }
        }

        .listMenus-btn {
          display: flex;
          padding: 32px 32px 48px 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          background: #e3eded;

          .form-name {
            color: $goldMid;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            /* 177.778% */
            letter-spacing: 0.54px;
          }

          .listMenu-icon {
            justify-content: center;
            align-items: center;
            display: flex;
            line-height: 24px;
            padding: 24px;
          }

          .listMenu-btn {
            text-decoration: none;
            display: flex;
            // align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 4px;
            margin-top: 10px;
            margin-bottom: 16px;

            // padding: 8px 8px 8px 12px;
            .listMenu-btn-text {
              width: 100%;
              align-items: center;

              .v-select {
                width: 100%;
              }
            }

            .select-year-month {
              display: flex;
              flex-flow: column !important;
              gap: 16px;
            }
          }

          .arrow-svg {
            width: 36px;
            height: 48px;
          }

          .title-btn {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0px;
            margin: 0;
            gap: 16px;
            width: 100%;
            height: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.42px;
            color: $monoBlack;

            .title-require {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0px 4px 2px;
              width: auto;
              height: 20px;

              background: $goldMid;
              border-radius: 4px;
              color: white;
              font-weight: 500;
              font-size: 11px;
              line-height: 18px;
              text-align: center;
              letter-spacing: 0.03em;
            }
          }
        }

        .page-title {
          margin-bottom: 50px;
        }

        .v-select.v-text-field input {
          width: 496px;
          height: 40px;
          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          .v-input__slot {
            border: 1px solid rgba(42, 42, 48, 0.1);
            border-radius: 4px;
            background: $monoOffWhite !important;
          }
        }

        .divider {
          width: 100%;
        }

        .title-btn-select-scope {
          margin-bottom: 12px;
        }

        .listMenus-btn {
          padding: 20px;
        }

        .csv-file {
          background: #f7f7f2;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;
          margin-top: 10px;
        }

        .csv-input {
          background: $monoOffWhite;
          height: 252px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 16px;
          gap: 16px;
          border-bottom: 1px solid rgba(42, 42, 48, 0.1);
          border-radius: 4px;

          .import-csv {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            height: 164px;
            width: 100%;
            border: 1px dashed #c8deea;
            padding: 32px 8px;
            gap: 8px;

            .upload-icon {
              width: 50px;
              height: 64px;
            }

            .csv-input-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .csv-input-text {
                font-weight: 400;
                font-size: 15px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.75px;
                color: $monoMid;
              }
            }
          }
        }

        .csv-filename {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          width: 100%;
          height: 60px;
          border-bottom: 1px solid $dividerBlack;

          &-detail {
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: $monoBlack;
            word-break: break-all;
            //display: flex;
            //flex-direction: row;
            //align-items: center;
            padding: 0px 0px 0px 16px;
            //gap: 8px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .remove-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 60px;
            height: 60px;

            &-detail {
              cursor: pointer;
            }
          }
        }

        .csv-download {
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          justify-content: space-between;
          padding: 0px;
          width: 100%;
          margin-top: 8px;
          margin-bottom: 20px;

          .des-template-csv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .label-delele {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px 4px 2px;
            width: auto;
            height: 20px;

            background: $redMid;
            border-radius: 4px;
            color: white;
            font-weight: 500;
            font-size: 11px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.03em;
            cursor: pointer;
          }
        }

        .csv-description {
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.75px;
          color: $monoBlack;
          font-weight: 400;
        }

        .csv-description-download {
          color: $goldMid;
          cursor: pointer;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.75px;
          text-decoration-line: underline;
          position: relative;
          width: max-content;
          margin-inline: auto;

          .underline {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 0;
            height: 1px;
            background-color: $goldMid;
            transition: width 0.3s ease;
            bottom: 2px;
          }

          &.disable {
            cursor: text;
            color: $monoBlack;
          }

          &:hover {
            text-decoration: underline;

            .underline {
              width: 100%;
            }
          }
        }
      }
    }

    .head-category-popup {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      background: #eff5f5;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      border-bottom: solid 1px rgba(121, 134, 134, 0.12);

      .log-confirm_text {
        height: 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }

      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #f7f7f2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;

        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }

        .close-active {
          display: none;
        }

        .close {
          display: block;
        }

        &:hover {
          background: $goldLight;

          .close-active {
            display: block;
          }

          .close {
            display: none;
          }

          .list-emission-close__text {
            color: $monoWhite;
          }
        }

        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;

          .close-active {
            display: none;
          }

          .close {
            display: block;
          }

          .list-emission-close__text {
            color: $monoBlack;
          }
        }
      }
    }

    .head-emission-category-border {
      width: 100%;
      height: 1px;
      background: rgba(121, 134, 134, 0.12);
    }
  }

  @media (max-width: 768px) {
    .head-category-popup {
      padding: 16px 20px !important;
    }

    .form-main {
      margin-top: 134px !important;
    }
  }

  @include desktop {
    .listMenu-page {
      .listMenus-btn {
        display: flex;
        // flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: 560px;
        margin: auto;
        padding: 32px 32px 48px;

        .listMenu-btn {
          // margin: 0 12px 24px 12px;
          display: flex;
          flex-flow: column;
          // align-items: center;
          justify-content: center;
          margin-top: 10px;
          padding: unset;

          &:hover {
            cursor: pointer;
          }

          img.listMenu-icon {
            display: flex;
            width: 20px;
            height: 36px;
            transform: rotate(90deg);
            flex: none;
            order: 0;
            flex-grow: 0;
          }

          .input-title {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0 16px;
            position: static;
            width: 496px !important;
            height: 40px;
            left: 0;
            top: 32px;
            background: $monoOffWhite;
            box-sizing: border-box;
            box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
            border-radius: 4px;
            flex: none;
            align-self: stretch;
            flex-grow: 0;
            margin: 8px 0px;
            outline: none;
          }

          .listMenu-btn-text {
            display: flex;
            flex-flow: column;

            // padding: 26px 0 22px;
            .listMenu-text {
              padding-left: 0;
            }
          }

          .listMenu-scope {
            /* Auto layout */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 7px 16px 9px;

            width: 496px;
            height: 40px;

            /* Mono/OffWhite */

            background: $monoOffWhite;
            box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
              0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
              0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
            border-radius: 4px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
          }

          .select-year-month {
            flex-flow: row !important;
          }
        }
      }

      .submit-register-btn {
        // background: $goldMid !important;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
          0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
          0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
          0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
          0px 36px 33px 0px rgba(160, 181, 186, 0.07);
        border-radius: 4px;
        transition: 0.1s ease-out;

        .v-btn__content {
          color: white;
        }
      }

      .list-menu-title {
        height: 40px;
        margin-bottom: 50px;
        width: 100%;
        padding-left: 40px;

        .page-title {
          margin-bottom: 0;
        }

        .return__header {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 !important;
          width: 161px;

          .list-emission-return {
            height: 20px;

            .return-active {
              display: none;
            }
          }

          &:hover {
            .list-emission-return {
              .return {
                display: none;
              }

              .return-active {
                display: block;
              }
            }
          }

          &:active {
            .list-emission-return {
              .return {
                display: block;
              }

              .return-active {
                display: none;
              }
            }
          }
        }
      }

      .list-menu--title {
        padding-left: 40px;
      }

      .list-menu-registration {
        width: 100%;
        max-width: calc(560px);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 32px;
        gap: 16px;
        height: 104px;
        background: $bgLight;
        border-top: 1px solid rgba(121, 134, 134, 0.12);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .common-btn {
          width: 100%;

          .v-btn__content {
            color: $monoWhite;
          }
        }
      }

      .csv-download {
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        justify-content: space-between;
        padding: 0px;
        width: 100%;
        height: 85px;
        margin-top: 8px;
      }

      .helper-icon {
        position: relative;
        margin-left: 6px;
        right: unset;

        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
