<template>
  <dialog-not-include-close-icon :dialog="dialog" @close="close" :isStyleForCardText="false" :isHiddenScroll="false">
    <template v-slot:title>
      <span class="title-popup">{{ $t('new_cfp.title_add_a_lower_level') }}</span>
    </template>
    <template v-slot:content>
      <v-radio-group v-model="actionApproval" hide-details class="pt-0 pb-0">
        <v-list-item class="mt-0" v-for="item in items" :key="item.id">
          <v-list-item-action class="py-4 pl-8">
            <v-radio :ripple="false" :label="item.title" :value="item.id" color="success">
              <template v-slot:label>
                <div class="d-flex flex-column">
                  <div class="sub-title">{{ item.title }}</div>
                </div>
              </template>
            </v-radio>
          </v-list-item-action>
        </v-list-item>
      </v-radio-group>

      <div class="select mb-4">
        <infinite-scroll
          :isDisabled="isDisabledSelect"
          item-text="text"
          item-value="id"
          :label="getLabelDetail"
          :items="approvedProductList"
          :model="valueData"
          inputFieldElementId="selectAddProcess_inputField"
          dropdownElementId="selectAddProcess_dropdown"
          searchInput="selectAddProcess_searchInput"
          tooltipColumn="tooltipAddProcess_tooltipColumn"
          isReturnObject
          @updateMasterDb="getPulldownAddProcess"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <common-button @action="close" :label="$t('new_cfp.button_cancel')" />
      <common-button
        :label="$t('new_cfp.button_decision')"
        @action="submit"
        :class="isDisabledSubmit ? 'btn-disabled' : 'right-button-popup'"
        :disabled="isDisabledSubmit"
      />
    </template>
  </dialog-not-include-close-icon>
</template>
<script>
import DialogNotIncludeCloseIcon from '@/components/dialogs/base/DialogNotIncludeCloseIcon';
import CommonButton from '@/components/commonApp/Button.vue';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
export default {
  name: 'AddALowerLevelPopup',
  components: { DialogNotIncludeCloseIcon, CommonButton, InfiniteScroll },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Number,
      default: 0,
    },
    approvedProductList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: this.$t('new_cfp.Radio_button_add_a_sub_level_with_an_empty_item'),
        },
        {
          id: 2,
          title: this.$t('new_cfp.Radio_button_adding_approved_product_to_a_lower_level'),
        },
      ],
      actionApproval: 1,
      valueData: {
        id: '',
        text: '',
      },
    };
  },
  computed: {
    getLabelDetail() {
      return this.$t('new_cfp.label_select_add_lower_level');
    },
    isDisabledSelect() {
      return this.actionApproval === 1 ? true : false;
    },
    isDisabledSubmit() {
      return this.actionApproval === 2 && this.valueData.id === '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit', {
        status: this.actionApproval,
        selectValue: this.valueData,
        indexBoundary: this.data,
      });
      this.actionApproval = '';
      this.valueData = '';
    },
    getPulldownAddProcess(newVal) {
      if (newVal) {
        this.valueData = newVal;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
.v-application .select {
  width: 242px;
  margin-left: 54px;
}
.v-input--radio-group__input {
  .v-list-item:first-child {
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
  }
}
::v-deep .sub-title {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}
::v-deep .select-item .v-input__slot {
  background: $monoWhite !important;
}
</style>
