<template>
  <div class="infinite-scroll" :class="{ disabled: isDisabled }">
    <div
      :id="inputFieldElementId"
      class="input-field"
      ref="box"
      :class="{ active: isDropdown || focusInput, 'has-error': !!errorMessage }"
    >
      <input
        v-model="searchtext"
        :placeholder="label"
        :id="searchInput"
        :disabled="isDisabled"
        class="search-field"
        maxlength="255"
        @focus="handleShowDataList"
        @blur="handleBlurInput"
        @keyup="debouncedSearch"
      />
      <div class="append-inner" @click="handleDropdown">
        <div class="append-icon">
          <img
            v-if="isIconSearch"
            :src="require('@/assets/icons/pulldown/search-icon.svg')"
            alt="search icon"
            class="search-icon"
          />
          <i v-else class="v-icon notranslate mdi mdi-menu-down theme--light primary--text icon"></i>
        </div>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
    <div v-show="isDropdown" :id="dropdownElementId" class="dropdown-show" :style="'width:100%'">
      <v-virtual-scroll
        v-if="isHasData"
        bench="3"
        :items="filteredCategories"
        item-height="40"
        :min-height="40"
        :height="scrollHeight"
        min-width="94"
        max-width="100%"
        ref="virtualScroll"
      >
        <template v-slot:default="{ item }">
          <div
            class="dropdown-item"
            :class="{ selected: searchtext === item[itemText], 'unit-list': typeList === 'unit' }"
            @mouseover="toolTipOn(item[itemText])"
            @mouseleave="toolTipOff()"
            @mousemove="updateTooltipPosition($event)"
            @click="selectedDataPulldown(item)"
          >
            <div class="item-text">{{ item[itemText] }}</div>
          </div>
        </template>
      </v-virtual-scroll>
      <div v-else class="dropdown-item">
        <div class="item-text">{{ $t('new_cfp.no_data_available') }}</div>
      </div>
    </div>
    <div :id="tooltipColumn" class="tooltip"></div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
export default {
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    model: [String, Object, {}],
    isDisabled: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isCfp: {
      type: Boolean,
      default: false,
    },
    isReturnObject: {
      type: Boolean,
      default: false,
    },
    dropdownElementId: {
      type: String,
      default: 'dropdown-show',
    },
    inputFieldElementId: {
      type: String,
      default: 'input-source',
    },
    tooltipColumn: {
      type: String,
      default: 'tooltip-column',
    },
    searchInput: {
      type: String,
      default: 'search-input',
    },
    isIconSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdown: false,
      boxWidth: 0,
      searchtext: '',
      filteredCategories: [],
      focusInput: false,
      isFocusSearch: false,
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearchData, 300);
  },
  mounted() {
    this.boxWidth = this.$refs.box.clientWidth;
    this.handleBlurInput();
  },
  computed: {
    isShowData() {
      return (this.searchtext || this.modelData) && !this.isFocusSearch;
    },
    modelData() {
      if (this.isReturnObject) {
        return (
          this.items?.find((item) => item[this.itemValue] === this.model?.[this.itemValue])?.[this.itemText] ||
          this.searchtext
        );
      } else {
        return this.items.find((item) => item[this.itemValue] === this.model)?.[this.itemText] || this.model;
        // return item exist in list || item is not exist in list (category in draft)
      }
    },
    scrollHeight() {
      return this.filteredCategories.length < 4 ? 40 * this.filteredCategories.length : 40 * 4;
    },
    isHasData() {
      return this.filteredCategories.length > 0;
    },
    dataIsNotExistInPulldown() {
      return this.isReturnObject
        ? {
            [this.itemValue]: null,
            [this.itemText]: this.searchtext,
          }
        : this.searchtext;
    },
  },
  methods: {
    handleDropdown() {
      if (this.isDisabled) return;
      this.boxWidth = this.$refs.box.clientWidth;
      this.isDropdown = !this.isDropdown;
      this.filteredCategories = this.items;
      this.handleClickOutside();
    },
    handleShowDataList() {
      if (this.isDisabled) return;
      this.filteredCategories = this.searchtext
        ? this.items.filter(cat => cat.name.includes(this.searchtext))
        : this.items;
      this.isDropdown = this.filteredCategories.length > 0 ? true : false;
      this.boxWidth = this.$refs.box.clientWidth;
      this.focusInput = !this.focusInput;
      this.handleClickOutside();
      this.$emit('focus');
    },
    handleBlurInput() {
      this.$emit('updateMasterDb', this.dataIsNotExistInPulldown);
      this.focusInput = false;
      this.$emit('blur');
    },
    closeDropdownOnClickOutside(event) {
      const dropdownElement = document.getElementById(this.dropdownElementId);
      const inputFieldElement = document.getElementById(this.inputFieldElementId);
      const inputSearchElement = document.getElementById(this.searchInput);
      if (
        dropdownElement &&
        inputFieldElement &&
        !dropdownElement.contains(event.target) &&
        !inputFieldElement.contains(event.target)
      ) {
        this.isDropdown = false;
        this.focusInput = false;
        this.isFocusSearch = false;
        if (inputSearchElement) inputSearchElement.blur();
        if (this.searchtext === '') {
          this.$emit('updateMasterDb', null);
        }
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
        document.removeEventListener('scroll', this.closeDropdownOnClickOutside);
      }
    },
    toolTipOn(item) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const font = '14px Source Han Sans';
      context.font = font;

      let width = context.measureText(item).width;

      const ele = document.getElementById(this.tooltipColumn);
      ele.innerHTML = item;

      if (width > this.boxWidth - 32 - 16 - 28) {
        // Number is the width padding, scrollbar, 2 finnal text
        ele.style.display = 'block';
      }
    },
    toolTipOff() {
      const ele = document.getElementById(this.tooltipColumn);
      ele.style.display = 'none';
    },
    updateTooltipPosition(event) {
      const parent = document.getElementById(this.dropdownElementId).getBoundingClientRect();
      const ele = document.getElementById(this.tooltipColumn);
      if (parent.width > 400) {
        ele.style.left = 0;
        ele.style.top = event.clientY - parent.y - 30 + 'px';
        ele.style.width = parent.width + 'px';
      } else {
        ele.style.width = 400 + 'px';
        ele.style.right = 0;
        ele.style.top = event.clientY - parent.y - ele.clientHeight + 30 + 'px';
      }
    },
    selectedDataPulldown(item) {
      this.$emit('updateMasterDb', this.isReturnObject ? item : item[this.itemValue]);
      this.searchtext = item[this.itemText];
      this.isDropdown = false;
    },
    handleSearchData() {
      const searchTerm = this.searchtext?.toLowerCase().trim();
      this.filteredCategories = this.items.filter((item) => item[this.itemText].toLowerCase().includes(searchTerm));
      this.boxWidth = this.$refs.box.clientWidth + 4;
      this.isDropdown = this.filteredCategories.length > 0 ? true : false;
      this.toolTipOff();
      this.handleClickOutside();
    },
    handleFocusSearch() {
      if (this.isDisabled) return;
      this.isFocusSearch = true;
      this.searchtext = this.modelData;
      this.boxWidth = this.$refs.box.clientWidth - 4;
      setTimeout(() => {
        let element = document.getElementById(this.searchInput);
        if (element) element.focus();
        this.isDropdown = this.filteredCategories.length > 0 ? true : false;
        this.handleClickOutside();
      }, 100);
    },
    handleClickOutside() {
      if (this.isDropdown) {
        document.addEventListener('click', this.closeDropdownOnClickOutside);
        document.addEventListener('scroll', this.closeDropdownOnClickOutside);
      } else {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
        document.removeEventListener('scroll', this.closeDropdownOnClickOutside);
      }
    },
  },
  watch: {
    items: {
      handler() {
        this.filteredCategories = this.items || [];
      },
      deep: true,
    },
    model(newVal) {
      if (newVal) {
        this.searchtext = this.model;
      }
    },
    $route: {
      handler() {
        this.searchtext = null;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.infinite-scroll {
  position: relative;
  width: 100%;

  &.disabled {
    .input-field {
      background: $monoLight !important;

      .selected-item {
        color: $monoMid !important;
      }

      &:hover {
        cursor: default;
      }
    }
  }

  .input-field {
    position: relative;
    width: 100%;
    height: 40px;
    background: $monoWhite;
    box-shadow: 0px 1.2365612984px 1.1335145235px 0px rgba(160, 181, 186, 0.24),
      0px 3.1273565292px 2.8667433262px 0px rgba(160, 181, 186, 0.17),
      0px 6.3795137405px 5.847887516px 0px rgba(160, 181, 186, 0.13),
      0px 13.1405925751px 12.045542717px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07) !important;
    border-radius: 4px;
    padding: 7px 0px 9px 16px;
    transition: 0.1s ease-out;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .search-field {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      outline: unset;
      width: 100%;

      &::placeholder {
        color: $monoMid;
      }
    }

    .append-inner {
      width: 30px;
      height: 40px;
      padding: 15px 15px 15px 5px;

      .append-icon {
        height: 10px !important;
        width: 10px !important;
        min-width: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .search-icon {
          width: 20px;
          height: 20px;
        }

        .icon {
          align-items: center;
          display: inline-flex;
          font-feature-settings: 'liga';
          font-size: 24px;
          justify-content: center;
          letter-spacing: normal;
          line-height: 1;
          position: relative;
          text-indent: 0;
          transition: none;
          vertical-align: middle;
          user-select: none;

          &::before {
            content: url('../../assets/icons/menu-down.svg');
            width: 10px;
            height: 30px;
            line-height: 20px;
          }

          &::after {
            background-color: currentColor;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 100%;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transform: scale(1.3);
            width: 100%;
            transition: opacity 0.1s cubic-bezier(0.4, 0, 0.6, 1);
          }
        }
      }
    }

    .selected-item {
      color: $monoBlack;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .error-message {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      padding: 0px 11px;
      margin-top: 3px;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.6px;
      word-break: break-word;
      overflow-wrap: break-word;
      word-wrap: break-word;
      color: $red;
    }

    &.search-field {
      padding-left: 0;
      box-shadow: unset !important;

      &:focus {
        border: unset !important;
      }
    }

    &:hover {
      cursor: pointer;
      background: $monoWhite;
    }

    &.has-error {
      border: 2px solid $redMid;
    }

    &.active {
      outline: 2px solid $blueMid;
      background: $monoWhite;

      .append-inner {
        .append-icon {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .dropdown-show {
    position: absolute;
    max-height: 200px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    margin-top: 2px;
    border-radius: 8px;
    z-index: 999;
    overflow-y: hidden;
    overflow-x: hidden;

    .v-virtual-scroll::-webkit-scrollbar {
      width: 16px;
    }

    .v-virtual-scroll::-webkit-scrollbar-thumb {
      background-color: $bgMid;
      border-radius: 10px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    .v-virtual-scroll__item {
      z-index: 101;
    }

    .dropdown-item {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      height: 40px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      position: relative;

      &:hover {
        cursor: pointer;
        background: $bgLight;
      }

      &.selected {
        background: $bgCusLight;
        color: $monoBlack;
      }

      .item-text {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.unit-list {
        width: 100% !important;
      }
    }
  }

  .tooltip {
    display: none;
    position: absolute;
    z-index: 999;
    word-wrap: break-word;
    border: 1px solid rgba(42, 42, 48, 0.1);
    background: $monoWhite;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
      0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
      0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
      0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
      0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    color: $monoBlack;
    padding: 5px;
    min-width: 139px;
    top: 73px;
    width: 400px;
    right: -20px;
  }
}

.search-pulldown-item {
  .search-field {
    &::placeholder {
      color: $monoLight;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
  }
}
</style>
