<template>
  <div class="title-bar">
    <div class="title-bar__text">{{ title }}</div>
    <div class="title-bar__selectbox">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  background: $bgDeep;
  display: flex;
  padding: 8px 20px;
  flex-direction: row;
  height: 44px;
  justify-content: space-between;
  &__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03;
    color: $monoWhite;
  }
}
@include desktop {
  .title-bar {
    padding: 8px 24px 8px 40px;
  }
}
</style>
