<template>
  <div class="input-field" :class="customizeClass">
    <v-text-field
      v-model="dataTitleModel"
      :placeholder="label"
      solo
      hide-details="auto"
      height="28"
      class="field"
      :disabled="isReadOnly"
      :error-messages="errorMessages"
      :class="{
        'read-only': isReadOnly,
        'has-error': isError || errorMessages.length > 0,
        'has-tooltip': isHasToolTip,
        'is-focus': isFocus,
      }"
      :id="idInputField"
      :tabindex="tabIndex"
      @focus="handleFocus"
      @change="handleChange"
      @blur="handleBlur"
      @keydown.enter.prevent="handlePressEnterButton"
      @beforeinput="preventInvalidChars"
    ></v-text-field>
    <img
      v-if="!isReadOnly && dataTitleModel"
      class="clear-data-input-search"
      :class="isTextAlignRight && 'text-align-input'"
      :src="iconClose"
      alt="close"
      @click="clearDataTitleModel"
      @mouseenter="isActiveCloseIcon = true"
      @mouseleave="isActiveCloseIcon = false"
    />
    <div class="input-field--tooltip" :class="{ 'has-tooltip': isHasToolTip }">{{ contentTooltip }}</div>
  </div>
</template>
<script>
import {
  $_helper_isNumberType,
  $_helper_isNegativeNumber,
  removeSpecialChars,
  formatNumberBySetting,
} from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isActiveCloseIcon: false,
      dataTitleModel: null,
      isFocus: false,
    };
  },
  props: {
    inputValue: {
      type: [String, Number],
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isClearData: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isTextAlignRight: {
      type: Boolean,
      default: false,
    },
    isNumberType: {
      type: Boolean,
      default: false,
    },
    isHasToolTip: {
      type: Boolean,
      default: false,
    },
    contentTooltip: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    year: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: [String, Array],
      default: '',
    },
    isPositiveNumber: {
      type: Boolean,
      default: false,
    },
    customizeClass: {
      type: String,
      default: '',
    },
    idInputField: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: String,
      default: '1',
    },
    getOriginalValue: {
      type: Boolean,
      default: false,
    },
    cutNumberObj: {
      type: Object,
      default: () => {},
    },
    isNumberInterger: {
      type: Boolean,
      default: false,
    },
    isPaging: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('settingApp', ['getDigitsBySetting']),
    iconClose() {
      if (!this.isActiveCloseIcon) {
        if (this.isError || this.errorMessages.length > 0) {
          return require('@/assets/icons/product/input-error.svg');
        } else {
          return require('@/assets/icons/product/close-input.svg');
        }
      }
      return require('@/assets/icons/product/close-input-active.svg');
    },
    decimalSeparatorComma() {
      return [1, 3].includes(this.getDigitsBySetting);
    },
  },
  methods: {
    clearDataTitleModel() {
      this.$emit('update:inputValue', '');
      this.$emit('input', '');
      this.dataTitleModel = '';
      this.isActiveCloseIcon = false;
    },
    handleFocus() {
      if (this.isNumberType) {
        // format input number when focus input
        const formatInput = this.decimalSeparatorComma
          ? removeSpecialChars(this.dataTitleModel)?.replace(/\./g, ',')
          : removeSpecialChars(this.dataTitleModel);
        this.dataTitleModel = formatInput;
      }
      this.isFocus = true;
      this.$emit('focus');
    },
    handleChange(value) {
      if (this.getOriginalValue) {
        this.$emit('change');
        if (this.isPaging) this.$emit('input', value);
        return;
      }
      let valueConvertToHalfWidth = convertNestedFullWidthToHalfWidth(value);
      this.dataTitleModel = valueConvertToHalfWidth;
      if (!$_helper_isNumberType(valueConvertToHalfWidth) && this.isNumberType) {
        this.clearDataTitleModel();
      } else if (this.isPositiveNumber && $_helper_isNegativeNumber(valueConvertToHalfWidth)) {
        this.clearDataTitleModel();
      } else if (this.isNumberInterger) {
        let regex = /^[0-9０-９]+$/;
        valueConvertToHalfWidth = valueConvertToHalfWidth.replace(/^[-,\.]+|[,\.]/g, '');
        this.dataTitleModel = regex.test(valueConvertToHalfWidth)
          ? valueConvertToHalfWidth.replace(/^0+(?=\d)/, '')
          : this.clearDataTitleModel();
      }
      this.$emit('change');
    },
    handleBlur() {
      if (this.getOriginalValue) {
        this.isFocus = false;
        return;
      }
      let realValue = this.dataTitleModel;
      if (this.isNumberType) {
        realValue = formatNumberBySetting(this.dataTitleModel);
      }
      if ($_helper_isNumberType(this.dataTitleModel) && this.isNumberType) {
        // this.dataTitleModel = formatValue(this.dataTitleModel)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
        this.checkAndFormatNumber(realValue);
      }
      this.isFocus = false;
      if (this.cutNumberObj?.isCut) {
        // get maximum number charactor
        realValue = realValue?.slice(0, this.cutNumberObj.maxCharactor);
      }
      this.$emit('blur', realValue);
      this.$emit('change');
      this.$emit('update:inputValue', realValue, this.index);
      this.$emit('input', realValue);
    },
    handlePressEnterButton() {
      this.$emit('handlePressEnterButton');
    },
    checkAndFormatNumber(realValue) {
      // realValue is number formatted
      if (realValue !== null && realValue !== undefined) {
        this.dataTitleModel = formatNumberByConditions(realValue, {
          isRealNumber: true,
          isAlowMore25digit: false,
        });
      }
    },
    preventInvalidChars(event) {
      if (!this.isNumberType) return;
      const char = event.data;
      let regex = this.decimalSeparatorComma ? /[0-9０-９,-]/ : /[0-9０-９.-]/;
      if (event.inputType === 'deleteContentForward' || event.inputType === 'deleteContentBackward') return;
      if (this.isNumberInterger) {
        regex = /[0-9０-９]/;
      }
      if (!char || !regex.test(char)) {
        event.preventDefault();
      }
    },
  },
  mounted() {
    this.dataTitleModel = this.inputValue;
    if (this.isNumberType) {
      //inputValue always real value
      this.checkAndFormatNumber(this.inputValue);
      this.handleBlur();
    }
  },
  watch: {
    inputValue() {
      this.dataTitleModel = this.inputValue;
      if (!this.inputValue) {
        this.isActiveCloseIcon = false;
      }
      if (this.isNumberType && this.inputValue === '') {
        // handle for case clear input
        this.dataTitleModel = '';
      } else if (this.isNumberType) {
        // inputValue always real value
        this.checkAndFormatNumber(this.inputValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: 100%;
  .field {
    .v-input__control {
      .v-input__slot {
        padding: 0 !important;
        .v-text-field__slot {
          border: 1px solid $bgLight;
          border-radius: 4px;
          line-height: 18px !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #404d50;
          height: 28px;

          input {
            padding: 12px;
            height: 28px;
          }

          label {
            padding: 0 12px;
            line-height: 18px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $monoBlack;
          }
        }
      }
    }
  }

  .disabled_title {
    .v-input__control {
      pointer-events: none;

      .v-input__slot {
        .v-text-field__slot {
          input {
            background: $bgLight;
            border: 1px solid rgba(42, 42, 48, 0.1);
          }
        }
      }
    }
  }

  .v-input {
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
    color: $monoMid;

    .v-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    ::v-deep input {
      &::placeholder {
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
}
.input-field {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 28px;
  background: $monoWhite;
  border: 1px solid $bgCusLight;
  box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 1;
  // margin-bottom: 4px;
  position: relative;

  .clear-data-input-search {
    position: absolute;
    top: 7px;
    right: 11px;
    cursor: pointer;
    &.text-align-input {
      left: 11px;
    }
  }
  .input-field--tooltip {
    display: none;
  }
  &:hover {
    .has-tooltip {
      &.input-field--tooltip {
        display: block;
        position: absolute;
        top: -60px;
        width: max-content;
        max-width: 100%;
        height: auto;
        left: 0;
        border-radius: 4px;
        padding: 2px 10px 4px;
        background: $bgGold;
        color: $monoWhite;
        border: 1px solid $goldHeavy;
        box-shadow: unset;
        white-space: pre-wrap;
      }
    }
  }
  &:focus-within {
    .has-tooltip {
      &.input-field--tooltip {
        display: none;
      }
    }
  }
}

::v-deep .v-input__slot {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-height: 28px;

  &::before {
    border-bottom: unset;
    display: none;
  }
  input {
    height: 18px;
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .input-field {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.field {
  &.has-error {
    ::v-deep .v-input__slot {
      border: 2px solid $redMid !important;
    }
    ::v-deep .error--text {
      margin-top: 4px;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.6px;
    }
  }
}
</style>
