<template>
  <div
    :key="reRenderKey"
    :class="buttonType"
    class="button-wrapper"
    @mousedown="handleBtnClick($event)"
    @focus="handleFocus($event)"
    @mouseleave="handleBlur($event)"
    tabindex="0"
  >
    <img v-if="getIcon" class="icon" :src="getIcon" alt="" />
    <span class="text">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconHover: {
      type: String,
      default: null,
    },
    buttonType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reRenderKey: 0,
    };
  },
  computed: {
    getIcon() {
      if (!this.icon) return;
      return require(`@/assets/icons/${this.icon}`);
    },

    getIconHover() {
      if (!this.iconHover) return;
      return require(`@/assets/icons/${this.iconHover}`);
    },
  },

  methods: {
    handleBtnClick() {
      this.$emit('action');
    },
    handleFocus(event) {
      event.target.classList.add('focus');
    },
    handleBlur() {
      this.reRenderKey++; // force re-render key to remove focus event;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  height: 40px;
  width: 100%;
  background: $monoOffWhite;
  border-radius: 4px;
  box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11),
    0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17),
    0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
  padding: 7px 16px 9px 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: 0.2s ease-out;
  vertical-align: middle;
  white-space: nowrap;
  .text {
    color: $monoBlack;
    text-align: center;
    font-family: 'Source Han Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
  }
  &.warning-btn {
    background: $goldMid;
    .text {
      color: $monoWhite;
    }
  }
  &.danger-btn {
    background: $redMid;
    .text {
      color: $monoWhite;
    }
  }
  &.colored {
    background-color: $goldMid;
    .text {
      color: $monoWhite;
    }
  }
  &:hover {
    cursor: pointer;
    background: $goldLight;
    .text {
      color: $monoWhite;
    }
    &.danger-btn {
      background: $redDark;
      .text {
        color: $monoWhite;
      }
    }
  }
  &.focus {
    border: 1px solid $blueMid;
  }
}
</style>
