<template>
  <div>
    <popup-fullscreen-header
      :isStyleImportPreview="false"
      v-model="dialogVisible"
      :titlePopup="$t('new_cfp.title_popup_preview_csv')"
      :retainFocus="false"
    >
      <template>
        <div class="form-main">
          <span class="messeage-preview">{{ $t('new_cfp.messeage_popup_preview_csv') }}</span>
          <div class="select-box mt-6">
            <span class="title-select-box">{{ $t('new_cfp.label_boundary_selection') }}</span>
            <div class="select mt-2">
              <infinite-scroll
                :label="getLabelDetail(valueData)"
                :items="boundariesList"
                :model="valueData"
                item-text="name"
                item-value="key"
                @updateMasterDb="updateMasterDb"
                id="select-preview"
                inputFieldElementId="inputFieldElementIdPopupPreview"
                searchInput="searchInputPopupPreview"
              />
            </div>
          </div>
          <div class="warning-box mt-10">
            <div class="py-2 px-6 d-flex align-center">
              <div class="warning-icon">
                <img :src="require('@/assets/icons/warning_preview_csv.svg')" alt="warning-icon" />
              </div>
              <div class="warning-text ml-4">
                <div v-html="$t('new_cfp.warning_one_popup_preview_csv')"></div>
              </div>
            </div>
          </div>
          <div class="checkbox-box mt-4 mb-2">
            <check-box-home-standard
              v-model="showLineError"
              :label="getTitleCheckBox"
              @change="changeCheckboxShowError"
            />
          </div>
          <div class="preview-table">
            <data-table
              tableName="previewCsv"
              :data-source="listData"
              :initGrid="initialized"
              :gridColumns="gridColumns"
              :allowAddNew="false"
              :isShowToolbar="false"
              :allowRightClick="true"
              :rightClickOptions="rightClickOptions"
              @initializedFilter="initializedFilter"
              @updateAutoFill="handleUpdateAutoFill"
            />
          </div>
          <div class="import-btn mt-10">
            <common-button
              class="submit-register-btn"
              :label="$t('new_cfp.button_import_popup_preview_csv')"
              type="colored"
              v-ripple="false"
              :isDisable="isDisableBtn"
              :loading="loading"
              @action="checkImportContent()"
            />
          </div>
        </div>
      </template>
    </popup-fullscreen-header>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import Vuetify from 'vuetify';
import debounce from 'lodash/debounce';
import PopupFullscreenHeader from '@/components/products/common/popup-fullscreen-header.vue';
import CommonButton from '@/components/utils/button.vue';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
import CheckBoxHomeStandard from '@/components/dashboard/home-standard/checkbox-standard';

import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import { validateNumberField, validateMaximumCharactor } from '@/utils/validate';
import HeaderSelect from '@/components/products/import-csv/HeaderSelect.vue';
import { setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import {
  formatNumberBySetting,
  $_helper_isNumberType,
  removeSpecialChars,
  $_helper_isNumberType_bySetting,
  checkNumberFormatted
} from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberRealNum } from '@/utils/convertNumber';
export default {
  name: 'popupPreview',
  components: {
    CommonButton,
    PopupFullscreenHeader,
    InfiniteScroll,
    CheckBoxHomeStandard,
    DataTable,
    HeaderSelect,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    csvFileContent: {
      type: Array,
      default: () => [],
    },
    boundariesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showLineError: false,
      valueData: '',
      actionApproval: '',
      gridColumns: [],
      listData: null,
      flexgrid: null,
      flexgridFilter: null,
      headerDataOptions: [
        {
          key: '',
          value: this.$t('register_data.checkbox_blank'),
        },
        {
          key: 'layer',
          value: this.$t('new_cfp.header_option_layer'),
        },
        {
          key: 'order',
          value: this.$t('new_cfp.header_option_order'),
        },
        {
          key: 'name',
          value: this.$t('new_cfp.header_option_name'),
        },
        {
          key: 'quantity',
          value: this.$t('new_cfp.header_option_quantity'),
        },
      ],
      selectedHeaderTableData: {},
      validation: {
        isExistNumber1InLayer: false,
        allHeaderNull: false,
        duplicateHeader: [],
      },
      rightClickOptions: { canInsert: false, canDelete: false },
      hasErrorMessageDefault: false,
      loading: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters('settingApp', ['getDigitsBySetting']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getTitleCheckBox() {
      return this.$t('new_cfp.checkbox_button_popup_preview_csv');
    },
    labelSelect() {
      return this.$t('new_cfp.label_boundary_selection');
    },
    isDisableBtn() {
      return this.valueData === '' || this.hasErrorMessageDefault;
    },
  },
  mounted() {
    this.defineTable();
  },
  methods: {
    getLabelDetail(val) {
      if (!val) return this.labelSelect;
    },
    updateMasterDb(newVal) {
      if (newVal) {
        this.valueData = newVal;
      }
    },
    formatNumber(input) {
      // If not a valid number, return input.
      if (!$_helper_isNumberType(input)) return input;

      return formatNumberRealNum(removeSpecialChars(input));
    },
    initialized(flexgrid) {
      this.flexgrid = flexgrid;
      flexgrid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        const { row, col } = e.range;
        const isColumnNumber = ['quantity'].includes(this.selectedHeaderTableData[column.binding]?.key);
        if (isColumnNumber) {
          const cellData = s.getCellData(row, col, false);
          if (checkNumberFormatted(cellData)) { 
            const decimalSeparatorComma = [1, 3].includes(this.getDigitsBySetting);
            const formatCell = decimalSeparatorComma
              ? removeSpecialChars(cellData)?.replace(/\./g, ',')
              : removeSpecialChars(cellData);
            s.setCellData(row, col, formatCell, false, true);
          }
        }
        if (column.binding === 'header_0') e.cancel = true; // disabled status column
      });
      flexgrid.pastingCell.addHandler((s, e) => {
        const column = s.columns[e.col];
        if (column.binding === 'header_0') e.cancel = true; // disabled status column
      });
      flexgrid.pasted.addHandler(() => {
        this.validateCsvContent();
      });
      flexgrid.cellEditEnded.addHandler((s, e) => {
        const column = s.columns[e.col];
        const { row, col } = e.range;
        const isColumnNumber = ['quantity'].includes(this.selectedHeaderTableData[column.binding]?.key);
        if (isColumnNumber) {
          let cellData = s.getCellData(row, col, false)?.toString();
          s.setCellData(row, col, this.formatNumber(cellData), false, true);
        }
        if (column.binding === 'header_0') e.cancel = true; // disabled status column
        this.validateCsvContent();
      });
      flexgrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.columnHeaders) {
          if (colBinding === 'header_0') return;
          this.handleHeaderPulldown(s, e, colBinding);
        } else {
          if (colBinding === 'header_0' && e.cell.innerHTML === this.$t('new_cfp.table_status_error')) {
            e.cell.classList.add('wj-state-invalid');
          }
        }
      });
      flexgrid.scrollPositionChanged.addHandler(
        debounce((s, e) => {
          this.hideTooltipOnScroll();
        }, 100),
      );
    },
    initializedFilter(filter) {
      this.flexgridFilter = filter;
    },
    prepareDefaultCsvContent(fileContent) {
      if (fileContent.length === 0) return [];
      this.flexgrid.columnGroups = this.getGridColumns(fileContent[0]);
      return fileContent;
    },
    validateCsvContent() {
      let validateColumns = {
        layer: [],
        order: [],
        name: [],
        quantity: [],
        missing_parent: ['missing_parent']
      };
      Object.keys(this.selectedHeaderTableData).forEach((key) => {
        if (this.selectedHeaderTableData[key]?.key && this.selectedHeaderTableData[key]?.key !== '') {
          validateColumns[this.selectedHeaderTableData[key]?.key].push(key);
        }
      });

      // check duplicate header
      let duplicateHeader = [];
      if (validateColumns[this.headerDataOptions[1]['key']].length > 1)
        duplicateHeader.push(this.headerDataOptions[1]['value']);
      if (validateColumns[this.headerDataOptions[2]['key']].length > 1)
        duplicateHeader.push(this.headerDataOptions[2]['value']);
      if (validateColumns[this.headerDataOptions[3]['key']].length > 1)
        duplicateHeader.push(this.headerDataOptions[3]['value']);
      if (validateColumns[this.headerDataOptions[4]['key']].length > 1)
        duplicateHeader.push(this.headerDataOptions[4]['value']);
      this.validation.duplicateHeader = duplicateHeader;

      // Set default and update after loop
      this.validation.isExistNumber1InLayer = false;

      const validateColumnsArr = [
        ...validateColumns['layer'],
        ...validateColumns['order'],
        ...validateColumns['name'],
        ...validateColumns['quantity'],
        ...validateColumns['missing_parent'],
      ];

      // set status column text
      if (validateColumnsArr.length === 0) {
        this.validation.allHeaderNull = true;
      }

      const items = this.validateDataBeingTree(this.listData?.sourceCollection);
      const sourceCollection = this.listData.sourceCollection;
      let errorCell = false;
      for (let i = 0; i < items.length; i++) {
        const item = _.cloneDeep(items[i]);
        let hasError = false;
        sourceCollection[i]['header_0'] = this.$t('new_cfp.table_status_normal');
        sourceCollection[i]['missing_parent'] = item['missing_parent'];
        sourceCollection[i]['duplicate_order'] = item['duplicate_order'];
        sourceCollection[i]['parent_invalid'] = item['parent_invalid'];
        validateColumnsArr.forEach((propName) => {
          // Check column number to format all cells of the column
          const binding = this.selectedHeaderTableData[propName]?.key;
          if (binding === 'quantity') {
            const cellData = item[propName]?.toString();
            if (cellData) {
              sourceCollection[i][propName] = this.formatNumber(cellData);
            }
          }
          const error = this.listData.getError(item, propName);
          if (error) {
            sourceCollection[i]['header_0'] = this.$t('new_cfp.table_status_error');
            hasError = true;
          }
          if (validateColumns['layer'].includes(propName) && item[propName] == 1) {
            this.validation.isExistNumber1InLayer = true;
          }
        });
        errorCell ||= hasError;
      }
      
      this.hasErrorMessageDefault = errorCell ||
        !this.validation.isExistNumber1InLayer ||
        this.validation.allHeaderNull ||
        this.validation.duplicateHeader.length > 0 ||
        Object.keys(this.selectedHeaderTableData).length !== 4;

      this.changeCheckboxShowError(this.showLineError);
    },
    checkImportContent() {
      // this.validateCsvContent();
      this.loading = true;
      const dataTree = this.buildTreeStructure(this.listData.sourceCollection, true);
      this.$emit('bindingDataToTree', dataTree, Number(this.valueData));
      this.loading = false;
    },
    getGridColumns(firstItem) {
      let columns = [];
      // create STATUS column
      columns.push({
        binding: 'header_0',
        minWidth: 180,
        maxWidth: 980,
        header: this.$t('new_cfp.table_field_status'),
        cssClassAll: 'attach-file hide-filter header-pulldown field-status',
        allowSorting: false,
      }
      );
      const keys = Object.keys(firstItem);
      const length = keys.length;

      // Remove key idRowNum show on table
      for (let index = 0; index < length - 1; index++) {
        const binding = keys[index];
        const header = this.headerDataOptions[index + 1]?.value || ''; // Lấy header value

        columns.push({
          binding: binding,
          minWidth: 180,
          maxWidth: 980,
          header: header,
          allowSorting: false,
          cssClassAll: 'header-pulldown',
        });

        if (header) {
          this.selectedHeaderTableData[binding] = this.headerDataOptions[index + 1] || {}; // set default header text
        }
      }
      return columns;
    },
    defineTable() {
      const data = this.prepareDefaultCsvContent(this.csvFileContent);
      this.listData = new CollectionView([...data], {
        getError: this.getError,
      });
      this.validateCsvContent();
      setMinMaxSizeColumns(this.flexgrid, data);
    },
    handleHeaderPulldown(s, e, colBinding) {
      const currentSelected = this.selectedHeaderTableData[colBinding];
      const self = this;
      e.cell.innerHTML = `<div id="${colBinding}"></div>`;
      new Vue({
        el: `#${colBinding}`,
        vuetify: new Vuetify(),
        render(h) {
          return h(HeaderSelect, {
            props: {
              value: currentSelected?.key || '',
              items: self.headerDataOptions,
            },
            on: {
              change(value) {
                if (value !== '') {
                  // set data if header text !== ''
                  self.selectedHeaderTableData[colBinding] = {
                    key: value,
                  };
                } else {
                  // delete data when select = ''
                  delete self.selectedHeaderTableData[colBinding];
                }
                self.listData.getError = null; // reset getError to null before check error again
                self.listData.getError = self.getError;
                self.validateCsvContent(); // set status column data
              },
            },
          });
        },
        beforeDestroy() {
          this.$destroy(); // clear distance
        },
      });
    },
    isSelectedOption(currentSelected, currentOption) {
      return currentSelected === currentOption ? 'selected' : '';
    },
    getError(item, propName) {
      switch (this.selectedHeaderTableData[propName]?.key) {
        case 'layer':
          return this.validateNumberRange(item, propName, { min: 1, max: 10 }) || this.validateLayerTree(item, propName) || this.validateLayerInvalid(item, propName);
        case 'order':
          return (
            this.validateRawNumber(item, propName) ||
            this.validateNumberRange(item, propName, { min: 0 }) ||
            validateMaximumCharactor(formatNumberBySetting(item[propName]), 25) ||
            this.validateOrderDuplicateTree(item, propName)
          );
        case 'quantity':
          return (
            this.checkNumberFormattedBySetting(item[propName]) ||
            this.validateIsNumber(item, propName) ||
            this.validateNumberRange(item, propName, { min: 0 }) ||
            validateMaximumCharactor(formatNumberBySetting(item[propName]), 25)
          );
        case 'name':
          return validateMaximumCharactor(item[propName], 128);
      }
      return null;
    },
    checkNumberFormattedBySetting(number) {
      // TODO: Check if number has formatted to number setting
      if ($_helper_isNumberType(number)) return null;
      return checkNumberFormatted(number) ? null : this.$t('validation.error_number_field')
    },
    validateLayerTree(item, propName) {
      if (item['missing_parent']) {
        return this.$t('new_cfp.validate_missing_item_parent')
      }
      return null;
    },
    validateOrderDuplicateTree(item, propName) {
      if (item['duplicate_order']) {
        return this.$t('new_cfp.validate_duplicate_order_in_parent')
      }
      return null;
    },
    validateLayerInvalid(item, propName) {
      if (item['parent_invalid']) {
        return this.$t('new_cfp.validate_parent_invalid')
      }
      return null;
    },
    validateIsNumber(item, headerBinding) {
      return $_helper_isNumberType_bySetting(item[headerBinding])
        ? validateNumberField(formatNumberBySetting(item[headerBinding]))
        : this.$t('new_cfp.validation_error_integer_number');
    },
    validateRawNumber(item, headerBinding) {
      // Validate to column do not formatter by setting
      return Number.isInteger(Number(item[headerBinding]))
        ? validateNumberField(item[headerBinding])
        : this.$t('new_cfp.validation_error_integer_number');
    },
    validateNumberRange(item, headerBinding, range = {}) {
      const { min, max } = range;
      const isInteger = Number.isInteger(Number(formatNumberBySetting(item[headerBinding])));
      if (!isInteger) {
        return this.$t('new_cfp.validation_error_integer_number');
      }
      const realNumber = Number(formatNumberBySetting(item[headerBinding]));
      // case: validate min number only
      if (!max && realNumber < min) {
        return this.$t('new_cfp.validation_error_positive_interger_number');
      }
      // case: validate number in range
      if (realNumber < min || (max && realNumber > max)) {
        return this.$t('new_cfp.validation_error_number_range');
      }
      return null;
    },
    changeCheckboxShowError(value) {
      if (value) {
        this.listData.filter = (item) => {
          let isError = false;
          Object.keys(this.selectedHeaderTableData).forEach((propName) => {
            const error = this.listData.getError(item, propName);
            if (error) {
              isError = true;
            }
          });
          return isError;
        };
      } else {
        this.flexgridFilter.clear();
      }
    },
    validateDataBeingTree(dataOnTable) {
      const originalDataTable = _.cloneDeep(dataOnTable);
      const dataBinding = this.buildTreeStructure(dataOnTable);
      const errorDataTable = this.checkDuplicates(originalDataTable, dataBinding);

      return errorDataTable;
    },
    generateId() {
      return Math.floor(Math.random() * 9000000000000) + 1000000000000;
    },

    // Recursive function to sort children by order
    sortTreeByOrder(node) {
      if (!node.children?.length) return;

      // Sort children by `order`
      node.children.sort((a, b) => parseInt(a.order) - parseInt(b.order));

      // Recursively sort each child node
      node.children.forEach(this.sortTreeByOrder.bind(this));
    },

    buildTreeStructure(dataOnTable, clearKey = false) {
      const keysToFind = ["layer", "order", "name", "quantity"];
      // Tìm các header và lưu trong mảng
      const headersObject = keysToFind.reduce((acc, key) => {
        const header = Object.entries(this.selectedHeaderTableData).find(([, value]) => value.key === key)?.[0] || null;
        acc[key] = header;
        return acc;
      }, {});


      const result = [];
      const nodeMap = new Map();
      for (let index = 0; index < dataOnTable.length; index++) {
        let item = dataOnTable[index];

        // Common properties to be set
        const commonProperties = {
          idNode: this.generateId(),
          children: [],
          cut_off: false,
          isOpen: true,
          quantity: formatNumberBySetting(item[headersObject.quantity]),
          name: item[headersObject.name],
          order: item[headersObject.order],
          layer: item[headersObject.layer]
        };

        // Apply the common properties to the item
        item = { ...item, ...commonProperties };

        // If `clearKey` is false, also add `indexByRow`
        if (!clearKey) {
          item.indexByRow = index;
        }

        const layer = parseInt(item.layer);
        if (!nodeMap.has(layer)) {
          nodeMap.set(layer, []);
        }
        nodeMap.get(layer).push(item);

        if (layer === 1) {
          // Root-level item
          result.push(item);
        } else {
          // Find the parent node from the map
          const parentLayer = layer - 1;
          const parentNodes = nodeMap.get(parentLayer) || [];
          const closestParent = parentNodes
            .slice()
            .reverse()
            .find((parent) => parseInt(parent.layer) === parentLayer);

          if (closestParent) {
            closestParent.children.push(item);
          }
        }
      }
      // Sort the root nodes and recursively sort their children
      result.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      result.forEach((rootNode) => this.sortTreeByOrder(rootNode));

      return result;
    },

    // Hàm thêm key missing_parent và duplicate vào array
    checkDuplicates(array, tree) {
      const duplicateIds = new Set();
      const allIds = new Set();
      const parentInvalidIds = new Set(); // Tập hợp chứa các `idRow` có parent bị invalid

      // Thu thập tất cả các id từ tree
      const collectIds = node => {
        allIds.add(node.indexByRow);
        if (node.children) {
          node.children.forEach(collectIds);
        }
      };
      tree.forEach(collectIds);

      // Kiểm tra duplicate trong tree
      const checkTreeDuplicates = node => {
        if (node.children) {
          const orders = new Map();
          node.children.forEach(child => {
            if (orders.has(child.order)) {
              // Đánh dấu duplicate cho node hiện tại và node đã tồn tại
              duplicateIds.add(child.indexByRow);
              duplicateIds.add(orders.get(child.order));

              // Đánh dấu con của node bị duplicate là parent_invalid
              const markChildrenParentInvalid = node => {
                if (node.children) {
                  node.children.forEach(child => {
                    parentInvalidIds.add(child.indexByRow); // Đánh dấu con của node parent_invalid
                    markChildrenParentInvalid(child); // Đệ quy cho các con
                  });
                }
              };
              markChildrenParentInvalid(child);
            } else {
              orders.set(child.order, child.indexByRow);
            }

            // Đệ quy kiểm tra các con
            checkTreeDuplicates(child);
          });
        }
      };
      tree.forEach(checkTreeDuplicates);

      // **Bổ sung kiểm tra các node ở layer = 1**
      const layer1Orders = new Map();
      tree.forEach(node => {
        if (node.layer === "1") {
          if (layer1Orders.has(node.order)) {
            // Đánh dấu duplicate
            duplicateIds.add(node.indexByRow);
            duplicateIds.add(layer1Orders.get(node.order));

            // Đánh dấu con của node bị duplicate là parent_invalid
            const markChildrenParentInvalid = node => {
              if (node.children) {
                node.children.forEach(child => {
                  parentInvalidIds.add(child.indexByRow); // Đánh dấu con của node parent_invalid
                  markChildrenParentInvalid(child); // Đệ quy cho các con
                });
              }
            };
            markChildrenParentInvalid(node);
          } else {
            layer1Orders.set(node.order, node.indexByRow);
          }
        }
      });

      // Kiểm tra missing_parent, duplicate_order, và parent_invalid trong array
      array.forEach(item => {
        item.missing_parent = !allIds.has(item.indexByRow); // true nếu không tồn tại trong allIds
        item.duplicate_order = duplicateIds.has(item.indexByRow); // true nếu bị duplicate
        item.parent_invalid = parentInvalidIds.has(item.indexByRow); // true nếu parent bị invalid
      });

      return array;
    },

    hideTooltipOnScroll() {
      const tooltip = document.querySelector('.wj-tooltip.wj-error-tip');
      if (tooltip) {
        tooltip.style.display = 'none';
      }
    },
    handleUpdateAutoFill() {
      this.validateCsvContent();
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.hideTooltipOnScroll);
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .header-select-custom {
    height: 100%;

    .v-select__selection {
      color: $monoBlack;
      font-size: 13px;
    }

    &.select-item .v-input__slot {
      background: transparent !important;
      box-shadow: unset !important;
      border: none;

      &:hover {
        background: transparent;
        box-shadow: unset;
      }
    }
  }

  .wj-flexgrid .wj-colheaders .wj-cell.header-pulldown {
    &.header-pulldown {
      padding: 0 !important;
      color: $monoBlack !important;
    }

    &.field-status {
      padding: 5px 8px !important;
    }
  }
  .preview-table {
    .wj-flexgrid {
      .wj-marquee {
        box-shadow: inset 2px 2px 0 0 $blueMid, inset -2px -2px 0 0 $blueMid !important;
      }
      .wj-cells {
        .header-pulldown {
          &.wj-state-invalid {
            color: $redMid !important;
          }
        }
      }
    }
    .select-item .v-input__slot .v-input__append-inner {
      padding-right: 0px !important;
    }
    .v-input__slot {
      padding: 7px 14px 9px 16px !important;
    }
  }
}

.modal-fullscreen {
  position: relative;
  max-width: 100%;

  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }

  .content-main {
    height: 100%;

    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }

    .form-main {
      padding: 142px 40px 80px 40px;
      color: $monoBlack;
      font-family: 'Source Han Sans JP';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.75px;
      position: relative;
      overflow: hidden;

      .select-box {
        .select {
          width: 240px;
        }
      }

      .warning-box {
        width: 980px;
        border-radius: 4px;
        border: 1px solid $redDark2;
      }

      .preview-table {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: unset;
      }

      .import-btn {
        margin-top: 40px;

        .submit-register-btn {
          display: flex;
          padding: 7px 16px 9px 16px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: $goldMid;
          box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11),
            0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17),
            0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
        }
      }

      .category-table {
        margin-left: unset !important;
      }
    }
  }
}
</style>
