<template>
  <v-select
    solo
    flat
    v-model="selectedValue"
    :items="items"
    item-text="value"
    item-value="key"
    class="select-type list-menu--select select-item header-select-custom"
    :menu-props="{ contentClass: 'select-menu export-select header-select-options z-index-high' }"
    @change="updateValue"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
    selectedValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    updateValue() {
      this.$emit('change', this.selectedValue);
    },
  },
};
</script>
